import { DoPost} from "@/store/Actions";
import querystring from "querystring";

// ХРАНИЛИЩЕ РЕЙСОВ

export default {
    state() {
        return {
            // список объектов
            inspReises: [],
            // время обновления кэша new Date().getTime()
            inspReisesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            inspReisesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getInspReises(state) {
            return state.inspReises;
        },
    },
    mutations: {

        // установить новый список объектов
        setInspReises(state, payload) {
            state.inspReises = payload;
            state.inspReisesCache = new Date().getTime()
            // console.log('setInspReises', state.inspReises)
        },
    },
    actions: {

        // получение рейсов по дате и ТС
        async doGetInspReises(context, payload) {
            let json = {};
            json.jsonTS = JSON.stringify(payload.ts);
            json.dateIndex = JSON.stringify(payload.dateIndex);

            await DoPost(
                '/InspReisRestController/getInspReisesByTSDateRemote',
                querystring.stringify(json),
                'setInspReises',
                'Получение рейсов'
            )
        },

        // добавление нового рейса
        async doAddReis(context, payload) {

            let json = {};
            json.jsonPark = JSON.stringify(payload.park);
            json.jsonInspection = JSON.stringify(payload.insp);
            json.jsonInspReis = JSON.stringify(payload.inspReis);

            return await DoPost(
                '/InspectionRestController/insertReisWithStationsManualRemote',
                querystring.stringify(json),
                null,
                'Внесение рейса'
            )
        },
    }
}
