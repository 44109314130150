<template>
  <div class="btn-group" role="group">
    <button
        type="button"
        class="btn"
        v-for="item in itemList"
        :class="classValue(item.id)"
        :key="item.id"
        :value="item.id"
        @click="buttonClick(item.id)"
        :disabled="this.required && item.id === null"
    >{{ item.title }}</button>
  </div>

</template>

<script>
export default {
  emits: ['update:model-value'],
  props: {
    'model-value': {
      required: false
    },
    // массив элементов [{id: any, title: string}]
    itemList: {
      type: Array,
      required: true
    },
    // требуется ввод значения
    'required': {
      type: Boolean,
      default: false
    },
    // только для чтения
    'readonly': {
      type: Boolean,
      default: false
    },
    // параметр, задающий можно ли "отжать" все кнопки
    // или одна из них должна быть обязатиельно нажата
    'allowAllUp': {
      type: Boolean,
      default: false
    },
    'error': {
      type: String
    }
  },
  data() {
    return {
      isInvalid: false,
      errorText: this.error
    }
  },
  methods: {
    buttonClick(value){
      if (value ===  this.modelValue && this.allowAllUp === true) {
        this.$emit('update:model-value', null)
      } else {
        this.$emit('update:model-value', value)
      }
    },
    classValue(id) {
      if (this.allowAllUp === true){
        return this.modelValue == id ? 'btn btn-outline-success' : 'btn btn-outline-secondary'
      }else {
        return this.modelValue == id ? 'btn-success' : 'btn-secondary'
      }
    },
    // при получении фокуса
    onFocus() {
      // сбрасываем инвалидность
      this.isInvalid = false;
      this.errorText = this.error;
    },
  },
  created() {
    // на всякий случай - меняем undefined на null
    this.$watch(() => this.modelValue, ()=> {
      if (this.modelValue === undefined) {
        this.$emit('update:model-value', null);
      }
    }, {immediate: true});
    // следим за изменением текста
    this.$watch(()=> this.error, () => {
      this.errorText = this.error;
    })
  }
}
</script>
