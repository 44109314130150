import { DoPost} from "@/store/Actions";
import querystring from "querystring";
import store from "@/store/Store";

// ХРАНИЛИЩЕ ТС

export default {
    state() {
        return {
            // список объектов
            ts: [],
            // время обновления кэша new Date().getTime()
            tsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            tsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getTS(state) {
            return state.ts;
        },
    },
    mutations: {
        // установить новый список объектов
        setTS(state, payload) {
            payload.forEach(value => {
                if (value.ts_deleted == false) {
                    state.ts.push(value)
                }
            })
            state.tsCache = new Date().getTime()
            // console.log('setTS', state.ts)
        },

        //очищаем список объектов
        clearTS(state) {
            state.ts = [];
        }

    },
    actions: {

        // получение ТС по парку
        async doGetTS(context, payload) {
            store.commit('clearTS')
            let json = {};
            json.jsonPark = payload.park;
            let postPark = JSON.stringify(json.jsonPark);

            await DoPost(
                '/TSRestController/getTSRemote',
                querystring.stringify(json) + postPark, //+ querystring.stringify(emptyJson),
                'setTS',
                'Получение ТС'
            )

        },
    }
}
