import { DoPost} from "@/store/Actions";
import querystring from "querystring";

// ХРАНИЛИЩЕ ПАРКОВ

export default {
    
    getters: {
        // вернуть список объектов
        getParks(state) {
            return state.parks;
        },
    },
    mutations: {
        // установить новый список объектов
        setParks(state, payload) {
            state.parks = payload;
            state.parksCache = new Date().getTime()
            // console.log('setParks', state.parks)
        },
    },
    state() {
        return {
            // список объектов
            parks: [],
            // время обновления кэша new Date().getTime()
            parksCache: null,
            // время жизни кэша в мс (null - бесконечно)

        }
    },
    actions: {

        // добавление объекта
        doGetParks: async () => {

            // чтобы получить formation, нужно сначала получить роль пользователя
            let payload = {}
            payload.user_name = localStorage.getItem('login');
            let userRole = await DoPost(
                '/UserRestController/getUserRoleRemote',
                payload,
                null,
                'Получение роли пользователя'
            )

            //чтобы получить доступные парки, нужно сначала получить formation
            let json = {};
            json.jsonUser = {
                id_user: 0,
                login: localStorage.getItem('login'),
                password: localStorage.getItem('pwd'),
                isMaster: false,
                identity: 257812337,
                user_role: userRole
            }


            let postData = JSON.stringify(json.jsonUser);

            const formation = await DoPost(
                '/ParkRestController/getAccessFormationsRemote',
                querystring.stringify(json) + postData,
                null,
                'Получение парков'
            )

            let jsonPark = {};
            jsonPark.app_user = json.jsonUser.login;
            jsonPark.app_pswd = json.jsonUser.password;
            jsonPark.withUnactiveParks = true;
            jsonPark.jsonFormation = formation[0];
            let postFormation= JSON.stringify(jsonPark.jsonFormation);
            await DoPost(
                '/ParkRestController/getAccessParksRemote',
                querystring.stringify(jsonPark) + postFormation,
                'setParks',
                'Получение парков'
            )
        },
    }
}
