<template>
    <navbar-menu class="fixed-top navbar-dark bg-dark navbar-expand">
<!--      <button class="left-menu-toggler" type="button" @click="onLeftMenuTogglerClick">-->
<!--      <button class="left-menu-toggler" type="button" >-->
<!--          <span class="navbar-toggler-icon"></span>-->
<!--      </button>-->
      <navbar-brand router-link to="/">Пассажиропотоки</navbar-brand>
      <navbar-collapse>
        <navbar-nav class="mr-auto">            
          <nav-item v-if="isAuth">
            <nav-link router-link to="/">Ручной ввод</nav-link>
          </nav-item>
        </navbar-nav >
        <navbar-nav >
          <nav-item v-if="isAuth">
            <nav-link @click="onLogout">Выход</nav-link>
          </nav-item>
        </navbar-nav>
      </navbar-collapse>   
    </navbar-menu>
</template>

<script>
export default {

  computed: {
    login() {
      return this.$store.getters.getLogin;
    },
    isAuth() {
      return this.$store.getters.getIsAuth;
    }
  },
  methods: {
    onLogout() {
      this.$store.dispatch('doLogout');
    }
  }
}
</script>

<style scoped>
</style>