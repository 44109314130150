import store from './Store';
import {
    getRawRequest,
    postRequest,
} from './Requests';

// отправка post-запроса
export async function DoPost(
    // url для отправки (относительно корня)
    url,
    // передаваемые данные
    json,
    // мьютейшен
    mutation,
    // заголовок сообщения
    caption,
) {
    try {
        // логируем
        if (process.env.NODE_ENV === 'development') {
            console.log(caption)
        }
        // базовый адрес
        const baseUrl = store.getters['settings/getBaseUrl'];
        // отправляем запрос
        const response = await postRequest(
            baseUrl + url,
            json,
            localStorage.getItem('tokenId')
        );
        if (response.data == '') {
            return true;
        }
        // парсируем JSON
        if (typeof (response.data) === 'string') {
            try {
                response.data = JSON.parse(response.data);
            } catch {
                throw new Error('Ошибка парсирования JSON-документа');
            }
        }

        // проверяем ответ на корректность
        if (response.data) {
            // запоминаем новую строку
            if (mutation) {
                store.commit(mutation, response.data);
            }
            // фиксируем успех
            return response.data;
        } else {
            // выходим с ошибкой
            return false;
        }
    } catch (e) {
        // выходим с ошибкой
        return false;
    }
}

// отправка get-запроса на получение чистого json
export async function DoGetRaw(
    // полный путь
    fullUrl,
    // мьютейшен
    mutation,
    // заголовок сообщения
    caption,
    // показывать сообщение об успешной операции
    showOk = true,
    // токен (можно не передавать)
    tid = null,
) {
    try {

        // отправляем запрос
        const response = await getRawRequest(
            fullUrl,
            tid
        );

        // парсируем JSON
        if (typeof (response.data) === 'string') {
            try {
                response.data = JSON.parse(response.data);
            } catch {
                throw new Error('Ошибка парсирования JSON-документа');
            }
        }

        // проверяем ответ на корректность
        if (response.data) {

            // запоминаем список
            if (mutation) {
                store.commit(mutation, response.data);
            }

            // подтверждаем
            if (showOk) {
                await store.dispatch('doAddMessage', {
                    caption,
                    message: 'Данные успешно получены',
                    type: 'ok'
                }, {root: true})
            }

            // фиксируем успех
            return response.data;
        } else {
            // ругаемся
            await store.dispatch('doAddMessage', {
                caption,
                message: 'Неправильная структура ответа от сервера',
                type: 'error'
            }, {root: true});

            // выходим с ошибкой
            return false;
        }
    } catch (e) {
        // ругаемся
        await store.dispatch('doAddMessage', {
            caption,
            message: e.message,
            type: 'error'
        }, {root: true});

        // выходим с ошибкой
        return false;
    }
}