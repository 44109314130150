<template>
  <card>
    <card-body class="p-1">
    <form class="my-2" v-on:submit.prevent>
        <div class="form-row">

            <!-- Пользовательская левая кнопка (до стандартных) -->
            <div class="col-0" v-if="$slots.preLeftCustomButton">
              <slot name="preLeftCustomButton"></slot>
            </div>

            <!-- Пользовательская левая кнопка 2  (до стандартных) -->
            <div class="col-0" v-if="$slots.preLeftCustomButton2">
              <slot name="preLeftCustomButton2"></slot>
            </div>

            <!-- Пользовательская левая кнопка 3  (до стандартных) -->
            <div class="col-0" v-if="$slots.preLeftCustomButton3">
              <slot name="preLeftCustomButton3"></slot>
            </div>

            <!-- Пользовательская левая кнопка 4 (до стандартных) -->
            <div class="col-0" v-if="$slots.preLeftCustomButton4">
              <slot name="preLeftCustomButton4"></slot>
            </div>

            <!-- Пользовательская левая кнопка 5 (до стандартных) -->
            <div class="col-0" v-if="$slots.preLeftCustomButton5">
              <slot name="preLeftCustomButton5"></slot>
            </div>

            <!-- Кнопка добавить -->
            <div class="col-0" v-if="!!buttonsExist.add">
                <button 
                    type="button" 
                    class="btn btn-success" 
                    @click="$emit('onAdd')"
                    :disabled="!buttonsEnabled.add"
                    title="Добавить"
                >
                <i class="fas fa-plus"></i>
                </button>
            </div>

            <!-- Кнопка изменить -->
            <div class="col-0" v-if="!!buttonsExist.edit">
                <button
                    type="button"
                    class="btn btn-warning"
                    @click="$emit('onEdit')"
                    :disabled="!buttonsEnabled.edit"
                    title="Изменить"
                >
                <i class="fas fa-pen"></i>
                </button>
            </div>

            <!-- Кнопка удалить -->
            <div class="col-0" v-if="!!buttonsExist.delete">
                <button
                    type="button"
                    class="btn btn-danger"
                    @click="$emit('onDelete')"
                    :disabled="!buttonsEnabled.delete"
                    title="Удалить"
                >
                <i class="fas fa-trash"></i>
                </button>
            </div>

            <!-- Кнопка включить -->
            <div class="col-0" v-if="!!buttonsExist.check">
              <button
                  type="button"
                  class="btn btn-success"
                  @click="$emit('onCheck')"
                  :disabled="!buttonsEnabled.check"
                  title="Включить"
              >
                <i class="fas fa-check-square"></i>
              </button>
            </div>

            <!-- Кнопка выключить -->
            <div class="col-0" v-if="!!buttonsExist.uncheck">
              <button
                  type="button"
                  class="btn btn-danger"
                  @click="$emit('onUncheck')"
                  :disabled="!buttonsEnabled.uncheck"
                  title="Выключить"
              >
                <i class="fas fa-square"></i>
              </button>
            </div>

            <!-- Кнопка сохранить -->
            <div class="col-0" v-if="!!buttonsExist.save">
              <button
                  type="button"
                  class="btn btn-success"
                  @click="$emit('onSave')"
                  :disabled="!buttonsEnabled.save"
                  title="Сохранить"
              >
                <i class="fas fa-save"></i>
              </button>
            </div>

            <!-- Кнопка отменить -->
            <div class="col-0" v-if="!!buttonsExist.cancel">
              <button
                  type="button"
                  class="btn btn-danger"
                  @click="$emit('onCancel')"
                  :disabled="!buttonsEnabled.cancel"
                  title="Отменить"
              >
                <i class="fas fa-ban"></i>
              </button>
            </div>

            <!-- Пользовательская левая кнопка -->
            <div class="col-0" v-if="$slots.leftCustomButton">
                <slot name="leftCustomButton"></slot>
            </div>

            <!-- Пользовательская левая кнопка 2 -->
            <div class="col-0" v-if="$slots.leftCustomButton2">
              <slot name="leftCustomButton2"></slot>
            </div>

            <!-- Пользовательская левая кнопка 3 -->
            <div class="col-0" v-if="$slots.leftCustomButton3">
              <slot name="leftCustomButton3"></slot>
            </div>

            <!-- Пользовательская левая кнопка 4 -->
            <div class="col-0" v-if="$slots.leftCustomButton4">
              <slot name="leftCustomButton4"></slot>
            </div>

            <!-- Пользовательская левая кнопка 5 -->
            <div class="col-0" v-if="$slots.leftCustomButton5">
              <slot name="leftCustomButton5"></slot>
            </div>

            <!-- Строка с фильтром -->
            <div class="col" v-if="filterExists">
                <input
                    type="text"
                    class="form-control"
                    id="inlineFormInput"
                    placeholder="Фильтр..."
                    @input="onFilterChanged"
                />
            </div>
            <div class="col" v-else></div>

            <!-- Пользовательская правая кнопка -->
            <div class="col-0" v-if="$slots.rightCustomButton">
              <slot name="rightCustomButton"></slot>
            </div>

            <!-- Кнопка обновить -->
            <div class="col-0" v-if="!!buttonsExist.refresh">
                <button 
                    type="button" 
                    class="btn btn-secondary" 
                    @click="$emit('onRefresh')"
                    :disabled="!buttonsEnabled.refresh"
                    title="Обновить"
                >
                <i class="fas fa-sync-alt"></i>
                </button>
            </div>
        </div>
    </form>
    </card-body>
  </card>
</template>

<script>
export default {
    emits: ['onAdd', 'onEdit', 'onDelete', 'onCheck', 'onUncheck', 'onRefresh', 'onSave', 'onCancel', 'onFilterChanged'],
    props: {
        // включенные кнопки
        'buttonsExist': {
            type: Object,
            default: function() {
                return {
                    add: false,
                    edit: false,
                    delete: false,
                    check: false,
                    uncheck: false,
                    save: false,
                    cancel: false,
                    refresh: true
                }
            }
        },
        // активные кнопки 
        'buttonsEnabled': {
            type: Object,
            default: function() {
                return {
                    add: false,
                    edit: false,
                    delete: false,
                    check: false,
                    uncheck: false,
                    save: false,
                    cancel: false,
                    refresh: true
                }
            }
        },
        // наличие строки фильтрации
        'filterExists': {
          type: Boolean,
          default: true
        },
        // задержка при изменении фильтра (мс)
        'filterDelay': {
            type: Number,
            default: 300
        }
    },
    methods: {
        // вызывается при изменении фильтра
        onFilterChanged(e) {
            const value = e.target.value.trim();            
            setTimeout(()=>{
                if (value === e.target.value.trim()) {
                this.$emit('onFilterChanged', {
                    prevValue: value,
                    newValue: e.target.value.trim()
                });
                }
            }, this.filterDelay);
        }        
    }
}
</script>

<style scoped>
  .card {
    margin-bottom: 3px;
  }
</style>