import sha256 from "sha256";
import {Base64} from "js-base64";

// функция возвращает hash пароля
export function getPasswordHash(login, password) {
    return sha256(Base64.encode(String(login).toLowerCase() + ":" + password))
}

// функция превращает дату в строку
export function dateToString(dt = new Date()) {
    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }
    if(dt == null) {
        return 'Не задано'
    }

    return padStr(dt.getFullYear()) + '-' + padStr(1 + dt.getMonth()) + '-' + padStr(dt.getDate());
}

// функция превращает строку в дату
export function stringToDate(str) {
    return new Date(str)
}

// объект возвращает разницу между датами
export const dateDiff = {

    // в миллисекундах
    inMilliseconds: function (d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt(t2 - t1);
    },

    // в секундах
    inSeconds: function (d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2 - t1) / 1000);
    },

    // в минутах
    inMinutes: function (d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2 - t1) / (60 * 1000));
    },

    // в часах
    inHours: function (d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2 - t1) / (3600 * 1000));
    },

    // в днях
    inDays: function (d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2 - t1) / (24 * 3600 * 1000));
    },

    // в неделях
    inWeeks: function (d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
    },

    // в месяцах
    inMonths: function (d1, d2) {
        const d1Y = d1.getFullYear();
        const d2Y = d2.getFullYear();
        const d1M = d1.getMonth();
        const d2M = d2.getMonth();

        return (d2M + 12 * d2Y) - (d1M + 12 * d1Y);
    },

    // в годах
    inYears: function (d1, d2) {
        return d2.getFullYear() - d1.getFullYear();
    }
}

// конвертирует  дату в количество дней с 2000-01-01
export function dateToND(dt = new Date()) {
    return dateDiff.inDays(new Date('2000-01-01T03:00:00'), dt)
}

// конвертирует количество дней с 2000-01-01 в дату
export function ndToDate(nd) {
    return new Date(new Date('2000-01-01').getTime() + nd * 24 * 3600 * 1000);
}

// Реализует функцию быстрой сортировки
// ARows - массив значений
// SortFn - функция сортировки
export function quickSort(ARows, SortFn, L = 0, R = ARows.length - 1) {
    let I, J;
    let P, T;

    // если длина массива меньше 2-х сортировать нечего
    if (ARows.length < 2) return;

    do {
        I = L;
        J = R;
        P = ARows[Math.floor((L + R) / 2)];
        do {
            while (SortFn(ARows[I], P) < 0) I++;
            while (SortFn(ARows[J], P) > 0) J--;

            if (I <= J) {
                if (I !== J) {
                    T = ARows[I];
                    ARows[I] = ARows[J];
                    ARows[J] = T;
                }
                I++;
                J--;
            }

        } while (I <= J);
        if (L < J) {
            quickSort(ARows, SortFn, L, J);
        }
        L = I;
    }
    while (I < R);
}

// Реализует функцию быстрого поиска
// AValue - значение, которое ищем,
// ARows - отсортированный массив значений
// SortFn - функция сортировки
// Возвращает объект {Index: number, Result: boolean)
// Result - значение найдено, Index - индекс с найденным значением
export function quickFind(AValue, ARows, SortFn) {
    let L, H, I, C;
    let Index;
    let Result = false;

    L = 0;
    H = ARows.length - 1;
    while (L <= H) {
        I = Math.floor((L + H) / 2);
        C = SortFn(ARows[I], AValue);
        if (C < 0) {
            L = I + 1;
        } else {
            H = I - 1;
            if (C === 0) {
                Result = true;
            }
        }
    }
    Index = L;
    // возвращаем результат
    return {
        Index,
        Result
    }
}

// функция для сортировки строк
export function strSortFn(str1, str2) {
    if (str1 == null)
        str1 = '';
    if (str2 == null)
        str2 = '';
    str1 = String(str1);
    str2 = String(str2);
    if (str1 < str2) return (-1)
    else if (str1 > str2) return (1)
    else return (0);
}

// функция для сортировки логических значений
export function boolSortFn(b1, b2) {
    if (b1 == null)
        b1 = false;
    if (b2 == null)
        b2 = false;
    b1 = Boolean(b1);
    b2 = Boolean(b2);
    if (b1 < b2) return (-1)
    else if (b1 > b2) return (1)
    else return 0;
}

// функция для сортировки цифр
export function numSortFn(num1, num2) {
    if (num1 == null)
        num1 = Number.MIN_SAFE_INTEGER;
    if (num2 == null)
        num2 = Number.MIN_SAFE_INTEGER;
    if (num1 < num2) return (-1)
    else if (num1 > num2) return 1
    else return (0);
}

// функция для сортировки типов рейса по сигнатурам
export function signSortFn(sign1, sign2) {
    if (typeof (sign1) !== 'string')
        sign1 = '';
    if (typeof (sign2) !== 'string')
        sign2 = '';

    // если оба рейса непроизводственные
    if ((sign1.substring(0, 1) == "_" || sign1.substring(0, 1) == "-") && (sign2.substring(0, 1) == "_" || sign2.substring(0, 1) == "-")) {
        // определяем рейс между остановочный или из парка/в парк
        //если первый из парка в парк
        if (sign1.substring(0, 1) == "_" && sign2.substring(0, 1) == "-") {
            return (-1);
        } else if (sign1.substring(0, 1) == "-" && sign2.substring(0, 1) == "_") {
            return (1);
        } else if (sign1.substring(0, 1) === sign2.substring(0, 1)) {
            if (sign1.substring(2) < sign2.substring(2)) return (-1)
            else if (sign1.substring(2) > sign2.substring(2)) return (1)
            else return (0);
        }
        //если второй производственный, а первый нет
    } else if ((sign1.substring(0, 1) == "_" || sign1.substring(0, 1) == "-") && (sign2.substring(0, 1) != "_" || sign2.substring(0, 1) != "-")) {
        return (1);
        // если первый производственный, а второй нет
    } else if ((sign1.substring(0, 1) != "_" || sign1.substring(0, 1) != "-") && (sign2.substring(0, 1) == "_" || sign2.substring(0, 1) == "-")) {
        return (-1);
        // если оба производственные
    } else if ((sign1.substring(0, 1) != "_" || sign1.substring(0, 1) != "-") && (sign2.substring(0, 1) != "_" || sign2.substring(0, 1) != "-")) {
        if (sign1 > sign2) return (1)
        else if (sign1 < sign2) return (-1)
        else return (0);
    }
}

//Конвертирует и возвращает дату и время из формата ISO 8601 (RFC-3339)
export function getDateTimeFormat(dateAndTime) {
    if (dateAndTime) {
        // "Отделяем" дату
        let result = dateAndTime.substring(0, 10);
        // Прибавляем время через пробел
        result = result + " " + dateAndTime.substring(11, 19);
        //Возвращаем отформатированные дату и время
        return result;
    } else {
        return '';
    }
}

//Конвертирует и возвращает время без секунд из значения в секундах
export function getTimeFormat(time) {
    if (time) {
        // часы
        let hours = Math.trunc(time / 3600);
        // минуты
        let minutes = Math.trunc((time - (hours * 3600)) / 60);
        //Возвращаем отформатированное время
        let result = (hours === 0 ? '00' : hours < 10 ? ('0' + hours) : hours) + ':' + (minutes === 0 ? '00' : (minutes < 10 ? ('0' + minutes) : minutes));
        return result;
    } else {
        return '00:00';
    }
}

//Конвертирует и возвращает время с секундами из значения в секундах
export function getTimeWithSecondsFormat(time) {
    if (time) {
        // часы
        let hours = Math.trunc(time / 3600);
        // минуты
        let minutes = Math.trunc((time - (hours * 3600)) / 60);
        // секунды
        let seconds = Math.trunc(time - (hours * 3600) - (minutes * 60));

        let result = (hours === 0 ? '00' : hours < 10 ? ('0' + hours) : hours) + ':' + (minutes === 0 ? '00' : (minutes < 10 ? ('0' + minutes) : minutes)) + ':' + (seconds === 0 ? '00' : (seconds < 10 ? ('0' + seconds) : seconds));
        return result;
    } else {
        return '00:00:00';
    }
}

//Конвертируем и возвращает пробег без секунд из значения в секундах
export function getLength(length) {
    if (length) {
        let result = length / 1000
        //Возвращаем отформатированное время
        return result.toFixed(3);
    } else {
        return 0;
    }
}



// очищает объект от лишних свойств, начинающихся с '__'
export function clearObj(obj) {
    const newObj = {}
    Object.keys(obj).forEach(key => {
        if (!key.includes('__')) {
            newObj[key] = obj[key];
        }
    })
    return newObj
}

// очищает массив объектов от лишних свойств, начинающихся с '__'
export function clearArr(arr) {
    return arr.map(obj => clearObj(obj))
}

// функция для сортировки маршрутов по номеру
export function routeNumSortFn(routeNum1, routeNum2) {
    //прежде всего нужно выделить буквенные и числовые частии номера маршрута.
    let routeNum1String = '';
    let routeNum1Int = '';
    for (let i = 0; i < routeNum1.length; i++) {
        if (Number.isInteger(Number(routeNum1.substring(i, i + 1)))) {
            routeNum1Int = routeNum1Int + routeNum1.substring(i, i + 1);
        } else {
            routeNum1String = routeNum1String + routeNum1.substring(i, i + 1);
        }
    }

    let routeNum2String = '';
    let routeNum2Int = '';
    for (let i = 0; i < routeNum2.length; i++) {
        if (Number.isInteger(Number(routeNum2.substring(i, i + 1)))) {
            routeNum2Int = routeNum2Int + routeNum2.substring(i, i + 1);
        } else {
            routeNum2String = routeNum2String + routeNum2.substring(i, i + 1);
        }
    }
    // сравниваем отделенные цифры
    if (Number(routeNum1Int) > Number(routeNum2Int)) return (1)
    else if (Number(routeNum1Int) < Number(routeNum2Int)) return (-1)
    else {
        //при равенстве цифр сравниваем буквы
        if (routeNum1String > routeNum2String) return (1)
        else if (routeNum1String < routeNum2String) return (-1)
        else return (0);
    }
}


