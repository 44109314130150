<template>
  <input
      type="text"
      v-model.trim="value"
      v-bind="$attrs"
      :class="{ 'is-invalid': isInvalid }"
      ref="length"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
  >
  <div class="invalid-feedback" v-if="!!errorText">{{ errorText }}</div>
  <div class="invalid-feedback" v-else>Введите пробег.</div>
</template>

<script>
export default {
  props: {
    'model-value': {
      required: true
    },
    // минимальное разрешенное значение
    'min-value': {
      type: Number,
      default: 0
    },
    // максимальное разрешенное значение
    'max-value': {
      type: Number
    },
    // требуется ввод значения
    'required': {
      type: Boolean,
      default: false
    },
    'error': {
      type: String
    }
  },
  emits: ['update:model-value'],
  data() {
    return {
      isInvalid: false,
      errorText: this.error
    }
  },
  computed: {
    value: {
      // то что отображаем
      get() {
        if (this.modelValue == null || this.modelValue === '' || !Number.isFinite(Number(this.modelValue)))
          return ''
        else
          return this.modelValue / 1000;
      },
      // то что возвращаем
      set(value) {
        if (value == null || String(value) === '') {
          this.$emit('update:model-value', null)
        }
        else
        if (Number.isFinite(Number(value))) {
          this.$emit('update:model-value', Math.round(Number(value * 1000)))
        }
        else {
          this.$emit('update:model-value', null)
        }
      }
    },
  },
  methods: {
    // проверка валидности
    isValid() {
      // разраешаем пустые строки, если допускается пустое значение
      if (this.modelValue == null) {
        return !this.required
      }

      // преобразуем в строку
      const newValue = String(this.modelValue);

      // целочисленное значение
      if (Number.isFinite(Number(newValue))) {
        // проверяем минимумы и максимумы
        const intValue = Number(newValue);
        return !(intValue < this.minValue || intValue > this.maxValue);
      }

      // все остальное ошибка
      return false;
    },
    // при получении фокуса
    onFocus() {
      // сбрасываем инвалидность
      this.isInvalid = false;
      this.errorText = this.error;
    },
    // при потере фокуса
    onBlur() {
      this.validate()
    },
    // при вводе значений
    onInput() {
      // проверяем цифры просле запятой
      let value = this.$refs.length.value;
      if (value != '') {
        if (!Number.isFinite(Number(value)) || this.modelValue !== Number(value * 1000)) {
          this.$forceUpdate();
        }
      }

      this.validate();
    },
    // вызывается для проверки формы
    validate() {
      const isValid = this.isValid()
      this.isInvalid = !isValid;
      return isValid
    },
    alarm(message) {
      this.errorText = message;
      this.isInvalid = true;
    }
  },
  created() {
    // на всякий случай - меняем undefined на null
    this.$watch(() => this.modelValue, ()=> {
      if (this.modelValue === undefined) {
        this.$emit('update:model-value', null);
      }
    }, {immediate: true})
    // следим за изменением текста
    this.$watch(()=> this.error, () => {
      this.errorText = this.error;
    })
  }
}
</script>

<style scoped>

input::placeholder {
  color: #a9a9a9;
}

</style>