<template>
  <input
      type="text"
      v-model.trim="value"
      v-bind="$attrs"
      :class="{ 'is-invalid': isInvalid }"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
  >
  <div class="invalid-feedback" v-if="!!errorText">{{ errorText }}</div>
  <div class="invalid-feedback" v-else-if="Number.isFinite(minValue) && Number.isFinite(maxValue)">Введите значение от {{ minValue}} до {{ maxValue}}.</div>
  <div class="invalid-feedback" v-else-if="Number.isFinite(minValue)">Введите значение от {{ minValue}}.</div>
  <div class="invalid-feedback" v-else-if="Number.isFinite(maxValue)">Введите значение до {{ maxValue}}.</div>
  <div class="invalid-feedback" v-else>Введите целочисленное значение.</div>
</template>

<script>
export default {
  props: {
    'model-value': {
      required: true
    },
    // минимальное разрешенное значение
    'min-value': {
      type: Number
    },
    // максимальное разрешенное значение
    'max-value': {
      type: Number
    },
    // требуется ввод значения
    'required': {
      type: Boolean,
      default: false
    },
    'error': {
      type: String
    },
    // обнуляем значение по клику, а если ничего не введено ставим ноль
    'zeroing': {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:model-value'],
  data() {
    return {
      isInvalid: false,
      errorText: this.error
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        if (value == null || String(value) === '') {
          this.$emit('update:model-value', null)
        }
        else
        if (Number.isInteger(Number(value))) {
          this.$emit('update:model-value', Number(value))
        }
        else {
          this.$emit('update:model-value', value)
        }
      }
    },
  },
  methods: {
    // проверка валидности
    isValid() {
      // разраешаем пустые строки, если допускается пустое значение
      if (this.modelValue == null || this.modelValue == '') {
        return !this.required
      }

      // преобразуем в строку
      const newValue = String(this.modelValue);

      // целочисленное значение
      if (Number.isInteger(Number(newValue))) {
        // проверяем минимумы и максимумы
        const intValue = Number(newValue);
        return !(intValue < this.minValue || intValue > this.maxValue);
      }

      // все остальное ошибка
      return false;
    },
    // при получении фокуса
    onFocus() {
      // сбрасываем инвалидность
      this.isInvalid = false;
      this.errorText = this.error;
      if (this.zeroing) {
        this.$emit('update:model-value', '')
      }
    },
    // при потере фокуса
    onBlur() {
      this.validate();
      if (this.modelValue == '' && this.zeroing){
        this.$emit('update:model-value', 0)
      }
    },
    // при вводе значений
    onInput() {
      this.validate();
    },
    // вызывается для проверки формы
    validate() {
      const isValid = this.isValid()
      this.isInvalid = !isValid;
      return isValid
    },
    alarm(message) {
      this.errorText = message;
      this.isInvalid = true;
    }
  },
  created() {
    // на всякий случай - меняем undefined на null
    this.$watch(() => this.modelValue, ()=> {
      if (this.modelValue === undefined) {
        this.$emit('update:model-value', null);
      }
    }, {immediate: true})
    // следим за изменением текста
    this.$watch(()=> this.error, () => {
      this.errorText = this.error;
    })
  }
}
</script>
