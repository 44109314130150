import CrudForm from './CrudForm.vue';
import EditTableCell from './EditTableCell.vue';
import EditTable from './EditTable.vue';
import InputInteger from "@/components/ui/custom/InputInteger";
import InputString from "@/components/ui/custom/InputString";
import InputSelect from "@/components/ui/custom/InputSelect";
import ReqField from "@/components/ui/custom/ReqField";
import TablePager from "@/components/ui/custom/TablePager";
import EditTablePanel from "@/components/ui/custom/EditTablePanel";
import AlignContainer from "@/components/ui/custom/AlignContainer";
import AlignPanel from "@/components/ui/custom/AlignPanel";
import InputButtonOption from "@/components/ui/custom/InputButtonOption";
import InputTime from "@/components/ui/custom/InputTime";
import InputLength from "@/components/ui/custom/InputLength";
import InputCurrency from "@/components/ui/custom/InputCurrency";
import InputFloat from "@/components/ui/custom/InputFloat";
import InputCheckboxSimple from "@/components/ui/custom/InputCheckboxSimple";
import InputTickingTime from "@/components/ui/custom/InputTickingTime";
import InputMinute from "@/components/ui/custom/InputMinute";
import Loading from "@/components/ui/custom/Loading";
import ConfirmMessageBox from "@/components/ui/custom/ConfirmMessageBox";

// регистрируем пользовательские компоненты
export default function (app) {

    app.component('crud-form', CrudForm);
    app.component('confirm-message-box', ConfirmMessageBox);
    app.component('edit-table-cell', EditTableCell);
    app.component('edit-table', EditTable);
    app.component('input-integer', InputInteger);
    app.component('input-string', InputString);
    app.component('input-select', InputSelect);
    app.component('req-field', ReqField);
    app.component('table-pager', TablePager);
    app.component('input-minute', InputMinute);
    app.component('edit-table-panel', EditTablePanel);
    app.component('align-container', AlignContainer);
    app.component('align-panel', AlignPanel);
    app.component('input-button-option', InputButtonOption);
    app.component('input-time', InputTime);
    app.component('input-length', InputLength);
    app.component('input-currency', InputCurrency);
    app.component('input-checkbox-simple', InputCheckboxSimple);
    app.component('input-float', InputFloat);
    app.component('input-ticking-time', InputTickingTime);
    app.component('loading', Loading);
}
