<template>
  <input-select ref="select" v-model="value" caption="Выберите Обследование..." :itemList="items"></input-select>
</template>

<script>
export default {
  props: ['model-value'],
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },
    items() {
      let rows = [];
      rows.push({
        id: -2,
        title: 'Новое обследование',
      })
      this.$store.getters['getInspections'].map(item => {
        rows.push({
          id: item.inspectionId,
          title: item.dateMinString + ' - ' + item.dateMaxString + ' ' + item.comm,
        })
      });
      return rows;
    }
  },
  methods: {
    validate() {
      return this.$refs.select.validate()
    }
  }
}
</script>
