import { createRouter, createWebHistory } from 'vue-router';
// import MainTitlePage from '../pages/main/MainTitlePage';
import store from '../store/Store';
import AuthPage from "@/pages/main/AuthPage";
import ManualInputPage from "@/pages/main/ManualInputPage";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/auth',
            name: 'auth',
            component: AuthPage
        },
        {
            path: '/:op(edit|add|alert)',
            component: ManualInputPage,
            props: true
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'profile',
            component: ManualInputPage
        },

    ]
});

// процедура загрузки приложения
async function loading(to) {
    // загружаем файл настроек
    const result = await store.dispatch('settings/doGetSettings');
    // ошибка настроек
    if (!result) {
        // устанавливаем панику
        await store.dispatch('doSetPanic', {
            panicMessage: 'Файл настроек не найден или имеет неверный формат.'
        })
        // переходим на страницу паники
        return ('/panic')
    }

    // фиксируем успешную загрузку
    await store.dispatch('doSetLoaded');

    // пытаемся обновить сессию
    let e = await store.dispatch('doAutoLogin');

    if (e == false) {
        // сбрасываем данные авторизации
        await store.commit('clearAuth');

    }
    // возвращаем исходный путь
    return to;
}

// проверяем пути перед входом
router.beforeEach((to)=>{

    // запускаем загрузку приложения
    if (!store.getters.getIsLoaded) {
        // если мы идем в окно паники во время паники - все хорошо
        if (to.name === 'panic' && store.getters.getIsPanic)
            return(true)
        // запускаем загрузку приложения
        return loading(to);
    }

    // не разрешаем заходить в панику напрямую
    if (to.name === 'panic') return('/');

    // авторизация есть, и пытаемся зайти на авторизацию
    if (store.getters.getIsAuth && to.name === 'auth') return('/');

    // авторизации нет, но пытаемся зайти куда-то ещё
    if (!store.getters.getIsAuth && to.name !== 'auth') {
        // запоминаем откуда уходим
        store.dispatch('doSetLoadedUrl', {
            loadedUrl: to
        });
        // идем авторизироваться
        return ('/auth')
    }

    // разрешаем
    return true;
})

export default router;