<template>
  <!-- Таблица -->
  <table class="table">
    <thead class="thead-dark" v-if="headerVisible">
    <tr>
      <th
          class="text-nowrap"
          v-for="(col) in columns"
          :key="col.field"
          :class="{'text-left': col.headerAlign === 'left', 'text-right': col.headerAlign === 'right', 'text-center': col.headerAlign === 'center'}"
          @click="columnClick(col)"
          @click.right.prevent="columnContextClick(col)"
      ><span>{{ col.caption }}</span><span
          v-if="col.isSortable"
          class="header-sortable-span"
          :class="col.sorted === 'asc' ? 'asc' : col.sorted === 'dsc' ? 'dsc' : 'both'"
      ></span>
      </th>
    </tr>
    </thead>
    <tbody class="thead-dark">
    <tr
        class="data-row"
        v-for="(row, rowIndex) in rowList"
        :key="row.__id"
        :class="{'bg-primary text-white': row === selectedRow}"
        :style="row.__rowStyle || {}"
        @click="rowClick(rowIndex)"
        @dblclick="rowDblClick(rowIndex)"
    >
      <edit-table-cell
          v-for="(col, colIndex) in columns"
          :key="col.field"
          :row="row"
          :col="col"
          :rowIndex="rowIndex"
          :colIndex="colIndex"
          @onCellChanged="onCellChanged"
          @onCellButtonClick="onCellButtonClick"
      >
      </edit-table-cell>
    </tr>
    </tbody>
  </table>
</template>

<script>

export default {
  emits: [
    // вызывается при двойном щелчке на строку - возвращает row
    'onRowDblClick',
    // вызывается при выделении строки - возвращает row
    'onRowSelect',
    // вызывается при щелчке на колонку левой кнопкой мыши - возвращает col
    'onColumnClick',
    // вызывается при щелчке на колонку таблицы правой кнопкой мыши - возвращает col
    'onColumnContextClick',
  ],

  props: {
    // Список колонок
    // Колонки имеют следующие поля
    // field: string - имя поля
    // subField: string - имя поля, куда вложенио поле
    // caption: string - заголовок в таблице
    // isCaption: boolean - является ли колонка заголовком
    // isFilterable: boolean - колонка может фильтроваться
    // isSortable: boolean - колонка может сортироваться
    // sorted: string - колонка отсортирована 'asc' - по возрастанию, 'dsc' - по убыванию
    // sortType: 'string' || 'number' || 'boolean'; - тип сортировки
    // dataAlign: string - выравнивание текста: 'left', 'right', 'center'
    // headerAlign: string - выравнивание текста заголовка: 'left', 'right', 'center'
    // sortFn: ( a, b ) => number;  - пользовательская функция сортировки
    // displayType: 'text' || 'html' || 'checkbox' || 'length' || 'float' || 'currency'  || 'minute' || 'button' || 'time' ; - тип отображения
    // displayFn: (row: Object, rowIndex: number) => String; - пользовательская функция отображения
    // show: boolean; - отображать колонку (да/нет)
    // cellStyle: Object - стили ячеек
    // ---
    // специализированные поля для displayType = 'button'
    // buttonCaption: string - заголовок кнопки (может содержать html)
    // buttonAttrs: Object - объект с атрибутами кнопки
    // buttonStyle: Object - объект со стилями кнопки
    // buttonClick: (row, rowIndex) => void; - вызывается при нажатии на кнопку
    // ---
    // специализированные поля для displayType = 'checkbox' || 'length' || 'minute' || 'float' || 'currency'...
    // inputAttrs: Object - объект с атрибутами элемента
    // inputStyle: Object - объект со стилями элемента
    columnList: {
      type: Array,
      default: () => []
    },

    // Список строк
    // Специальные поля:
    // __id: any - идентификатор строки (обязательное поле)
    // __isCaption: boolean - строка является заголовком
    // __cellStyle: Object - стили ячеек
    // __rowStyle: Object - стиль строки
    // __inputAttrs: Object - объект с атрибутами элемента
    // __inputStyle: Object - объект со стилями элемента
    // __buttonAttrs: Object - объект с атрибутами кнопки
    // __buttonStyle: Object - объект со стилями кнопки
    rowList: {
      type: Array,
      default: () => []
    },

    // Специальные поля для ячеек:
    // Имя поля__cellStyle: Object - стили ячеек
    // Имя поля__inputAttrs: Object - объект с атрибутами элемента
    // Имя поля__inputStyle: Object - объект со стилями элемента
    // Имя поля__buttonAttrs: Object - объект с атрибутами кнопки
    // Имя поля__buttonStyle: Object - объект со стилями кнопки

    // Активная строка
    selectedRow: {
      type: Object,
      default: null
    },

    // Видимость заголовка
    headerVisible: {
      type: Boolean,
      default: true
    }
  },

  computed: {

    // список колонок, дополненных значениями по умолчанию
    columns() {
      return this.columnList.map((col, index) => {
        col.field = col.field || `field${index}`;
        col.subField = col.subField || '';
        col.fieldTwo = col.fieldTwo || `field${index}`;
        col.caption = col.caption || ' ';
        col.isCaption = col.isCaption === true ? col.isCaption : false;
        col.isSortable = col.isSortable === false ? col.isSortable : true;
        col.isFilterable = col.isFilterable ? col.isFilterable : false;
        col.sortType = col.sortType === 'string' || col.sortType === 'number' || col.sortType === 'boolean' || col.sortType === 'sign' || col.sortType === 'routeNum' ? col.sortType : 'string';
        col.dataAlign = col.dataAlign === 'left' || col.dataAlign === 'right' || col.dataAlign === 'center' ? col.dataAlign : 'left';
        col.headerAlign = col.headerAlign === 'left' || col.headerAlign === 'right' || col.headerAlign === 'center' ? col.headerAlign : 'left';
        col.displayType =
            col.displayType === 'text' ||
            col.displayType === 'html' ||
            col.displayType === 'checkbox' ||
            col.displayType === 'length' ||
            col.displayType === 'float' ||
            col.displayType === 'currency' ||
            col.displayType === 'minute' ||
            col.displayType === 'time' ||
            col.displayType === 'tickingtime' ||
            col.displayType === 'integer' ||
            col.displayType === 'button'
                ? col.displayType : 'text';
        col.show = col.show !== false;

        return col.show ? col : null;
      }).filter(col => col !== null);
    }
  },

  methods: {

    // щелчок на строку
    rowClick(index) {
      this.$emit('onRowSelect', this.rowList[index]);
    },

    // двойной щелчок на строку
    rowDblClick(index) {
      this.$emit('onRowDblClick', this.rowList[index])
    },

    // щелчок на колонку левой кнопкой мыши
    columnClick(col) {
      this.$emit('onColumnClick', col)
    },

    // щелчок на колонку правой кнопкой мыши
    columnContextClick(col) {
      this.$emit('onColumnContextClick', col)
    },

    // вызывается при изменении данных в таблице
    onCellChanged(col, row, value) {
      row[col.field] = value;
    },

    // вызывается при щелчке на кнопку в таблице
    onCellButtonClick(col, row, index) {
      if (col.buttonClick) {
        col.buttonClick(row, index);
      }
    },
  },
};
</script>

<style scoped>

.data-row {
  cursor: pointer;
}

th {
  cursor: pointer;
  position: sticky;
  top: 0px;
}

.header-sortable-span {
  display: inline-block;
  width: 19px;
  height: 19px;
  vertical-align: bottom;
  background-repeat: no-repeat;
}

.header-sortable-span.both {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC");
}

.header-sortable-span.asc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==);
}

.header-sortable-span.dsc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=);
}

</style>