import { DoPost} from "@/store/Actions";
import querystring from "querystring";
import store from "@/store/Store";

// ХРАНИЛИЩЕ ОБСЛЕДОВАНИЙ

export default {
    state() {
        return {
            // список объектов
            inspections: [],
            // время обновления кэша new Date().getTime()
            inspectionsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            inspectionsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getInspections(state) {
            return state.inspections;
        },
    },
    mutations: {
        // установить новый список объектов
        setInspections(state, payload) {
            state.inspections = payload;
            state.inspectionsCache = new Date().getTime()
            // console.log('setInspections', state.inspections)
        },

        addInspection(state, payload) {
            state.inspections.unshift(payload);
        }

    },
    actions: {

        // сохранение нового Inspection
        async doAddInspections(context, payload) {

            let json = {};
            json.jsonMarsh = JSON.stringify(payload.route);
            json.jsonInspection = JSON.stringify(payload.inspection)
            let id = await DoPost(
                '/InspectionRestController/insertInspBlockRemote',
                querystring.stringify(json),
                 null,
                'Сохранение обследования'
            )
            if (id != false) {
                payload.inspection.inspectionId = id;
                store.commit('addInspection', payload.inspection);
                return id;
            } else {
                return false;
            }
        },

        // получение маршрутов по парку
        async doGetInspections(context, payload) {

            let json = {};
            json.jsonMarsh = payload.route;
            let postMarsh = JSON.stringify(json.jsonMarsh);
            await DoPost(
                '/InspectionRestController/getInspBlockRemote',
                querystring.stringify(json) + postMarsh,
                'setInspections',
                'Получение обследований'
            )

        },
    }
}
