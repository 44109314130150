<template>
    <modal>
        <modal-dialog class="modal-dialog-centered">
            <modal-content>
                <modal-header>			
                    <modal-title>
                        <slot name="title">{{title}}</slot>
                    </modal-title>	
                </modal-header>
                <modal-body>
                    <p>
                        <slot></slot>
                    </p>
                </modal-body>
                <modal-footer>
<!--                    <button class="btn btn-info" @click="$emit('onCancel')">Отмена</button>-->
                    <button class="btn btn-danger" @click="$emit('onOK')">Ок</button>
                </modal-footer>
            </modal-content>
        </modal-dialog>
    </modal>
</template>

<script>
export default {
    emits: ['onOK', 'onCancel'],
    props: {
      'title': {
        type: String,
        default: 'Подтверждение'
      },

    }
}
</script>

<style>


</style>