<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Добавление рейса</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form>
            <div class="form-group col">
                <label for="eventLength">Комментарий:</label>
                <req-field/>
                <input-string
                    id="comm"
                    class="form-control"
                    ref="comm"
                    v-model="comm"
                    autocomplete="off"
                    required
                />
              </div>
              <div class="form-group col">
                <label for="eventDuration">Кол-во привлеч. сотрудников:</label>
                <input-integer
                    id="employeesСount"
                    class="form-control"
                    ref="employeesСount"
                    v-model="employeesСount"
                    autocomplete="off"
                />
              </div>
          </form>
        </modal-body>
        <modal-footer>
          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>
        </modal-footer>
      </modal-content>
    </modal-dialog>
  </modal>
</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import InputString from "@/components/ui/custom/InputString";
import InputInteger from "@/components/ui/custom/InputInteger";


export default {
  components: {InputInteger, InputString},
  props: ['item', 'op', 'ts'],
  emits: ['onOK', 'onCancel'],
  mixins: [ValidateFormMixin],
  data() {
    return {
      comm: '',
      employeesСount:'',
    };
  },
  methods: {
    onSubmit() {
      if (this.validateForm(this.$refs)) {

        this.$emit('onOK', {
          comm: this.comm,
          employeesСount: this.employeesСount == ''? 0 : this.employeesСount,
        })
      }
    },
    //отменяем добавление
    onCancel() {
      this.$emit('onCancel')
    },
  },
  // вызывается при создании компонента
  created() {
    if(this.ts == null) {
      this.onCancel()
    }
  },
}
</script>

<style scoped>
form label {
  font-weight: bold;
}

</style>