<template>

  <align-container>
    <align-panel align="top">
      <crud-form
          :buttonsExist="{refresh: false}"
          :buttonsEnabled="crudButtonsEndabled"
          :filterExists="false"
      >

        <!-- Выбор предприятия-->
        <template #preLeftCustomButton>
          <depots-select
              id="park"
              v-model="park"
              @change="getRoutesAndTS"
              class="form-control"
              ref="park"
              required
              error="Выберите предприятие"
          />
        </template>

        <!-- выбор ТС -->
        <template #preLeftCustomButton2>
          <t-s-select
              id="ts"
              v-model="ts"
              class="form-control"
              @change="getReises"
              ref="ts"
              required
              error="Выберите ТС"
          />
        </template>

        <!--        <template #preLeftCustomButton2>-->
        <!--          <dropdown>-->
        <!--            <button-toggler target-ref="collapse1" class="btn dropdown-toggle btn-warning" style="background-color: white; border-color: lightgray" ref="ts">-->
        <!--              {{tsCBTitle}}-->
        <!--            </button-toggler>-->
        <!--            <dropdown-menu ref="collapse1">-->
        <!--              <dropdown-item-->
        <!--                  v-for="ts in tsRow"-->
        <!--                  :key="ts.ts_id"-->
        <!--                  @click="getReises(ts)">-->
        <!--                {{ ts.ts_garagnum }}-->
        <!--              </dropdown-item>-->
        <!--            </dropdown-menu>-->
        <!--          </dropdown>-->
        <!--        </template>-->

        <!-- Выбор маршрута -->
        <template #preLeftCustomButton3>
          <routes-select
              id="route"
              v-model="route"
              @change="getMarshVariants"
              class="form-control"
              ref="route"
              required
              error="Выберите маршрут"
          />
        </template>

        <!-- Период действия-->
        <template #preLeftCustomButton4>
          <marsh-variants-select
              id="marshVariant"
              v-model="marshVariant"
              @change="getRaces"
              class="form-control"
              ref="marshVariant"
              required
              error="Выберите период действия"
          />
        </template>

        <!-- Тип рейса -->
        <template #leftCustomButton>
          <race-type-select
              id="race"
              v-model="race"
              class="form-control"
              @change="setRace"
              ref="race"
              required
              error="Выберите тип рейса"
          />
        </template>

        <!-- Выбор обследования -->
        <template #leftCustomButton2>
          <inspection-select
              id="inspection"
              v-model="inspection"
              class="form-control"
              @change="setInsp"
              ref="inspection"
              required
              error="Выберите обследование"
          />
        </template>

        <!-- График -->
        <template #leftCustomButton3>
          <input-integer
              id="graph"
              v-model="graph"
              class="form-control"
              ref="graph"
              required
              placeholder="График"
              :min-value="0"
              autocomplete="off"
              style="width: 110px"
          />
        </template>

        <!-- Добавить новый рейс -->
        <template #leftCustomButton4>
          <button @click="onSubmit" class="btn btn-success" :disabled="isAddButtonDisabled">Добавить рейс</button>
        </template>

        <template #leftCustomButton5>
          <button
              type="button"
              class="btn btn-secondary"
              @click="onRefresh"
              title="Обновить"
          >
            <i class="fas fa-sync-alt"></i>
          </button>
        </template>

      </crud-form>

    </align-panel>

    <align-panel align="all">
      <races-edit-table
          :selectedRow="selectedRow"
          :rowList="rowList"
          @onRowSelect="onRowSelect"
          @onRowDblClick="onStationsDialogShow"
      ></races-edit-table>
    </align-panel>
  </align-container>

  <new-race-dialog
      v-if="op == 'add'"
      @onCancel="onTableCancel"
      @onOK="onTableSave"
      :ts="ts"
  >
  </new-race-dialog>

  <manual-tabular-station-inspection-dialog
      v-if="op == 'edit'"
      @onCancel="onTableCancel"
      :stationsList="stations"
      :ts="ts"
      :parkId="park"
  >
  </manual-tabular-station-inspection-dialog>

  <!-- Оповещение о существовании рейса, который пользователь хочет добавить -->
  <confirm-message-box
      v-if="op == 'alert'"
      @onOK="onTableCancel"
  >В данном временном промежутке такой рейс уже существует. Обновите страницу.
  </confirm-message-box>

  <!-- Оповещение о проблемах с сетью -->
  <confirm-message-box
      v-if="isSaveError"
      @onOK="onDialogCancel"
      title="Ошибка!"
  >Ошибка подключения, повторите попытку позже.
  </confirm-message-box>

</template>

<script>

import ValidateFormMixin from "@/components/ui/custom/mixins/ValidateFormMixin";
import DepotsSelect from "@/components/parks/ParksSelect";
import RoutesSelect from "@/components/routes/RoutesSelect";
import MarshVariantsSelect from "@/components/marshvariants/MarshVariantsSelect";
import RaceTypeSelect from "@/components/races/RaceSelect";
import RacesEditTable from "@/components/races/RacesEditTable";
import ManualTabularStationInspectionDialog
  from "@/components/manualtabularstationinspectiondialog/ManualTabularStationInspectionDialog";
import store from "@/store/Store";
import InspectionSelect from "@/components/inspections/InspectionSelect";
import InputInteger from "@/components/ui/custom/InputInteger";
import NewRaceDialog from "@/components/races/NewRaceDialog";
import {dateToND, dateToString, getTimeFormat, getTimeWithSecondsFormat} from "@/lib";
import TSSelect from "@/components/transport/TSSelect";

export default {
  mixins: [ValidateFormMixin],
  components: {
    TSSelect,
    NewRaceDialog,
    InputInteger,
    InspectionSelect,
    RaceTypeSelect,
    ManualTabularStationInspectionDialog,
    RacesEditTable, MarshVariantsSelect, RoutesSelect, DepotsSelect
  },

  props: [
    // операция add, edit, delete и т.д. передается через роутер
    "op",
    // идентификатор маршрута
    "route_id",
    // идентификатор варианта маршрута
    "route_variant_id",
    // идентификатор варианта расписания
    "schedule_variant_id",
    // идентификатор набора графиков
    "schedule_graph_set_id",
    // идентификатор графика
    "schedule_graph_id",
    // идентификатор редактируемого объекта
    "id",
  ],

  data() {
    return {
      // текст фильтра
      filterText: '',
      // выбранная строка
      selectedRow: null,
      // // отображать спиннер
      // isLoading: false,
      // парк
      park: null,
      // маршрут
      route: null,
      // период действия
      marshVariant: null,
      // тип рейса
      race: null,
      // выбор ТС
      ts: null,
      // выбор обследования
      inspection: null,
      // график
      graph: '',
      //строки таблицы
      rowList: [],
      // остановки для отображения в диалоге
      stations: [],
      // флаг включена ли копка добавления рейса (во избежание многократных нажатий)
      isAddButtonDisabled: false,
      // отображается ли диалог с оповещением об ошибку подключения
      isSaveError: false,
    }
  },

  computed: {

    // состояние crud-кнопок
    crudButtonsEndabled() {
      return {
        refresh: true
      }
    },

  },

  methods: {

    // закрываем оповещение о проблемах с сетью
    onDialogCancel() {
      this.isSaveError = false;
    },

    async onRefresh() {
      this.getReises();
      // получаем периоды действия
      const routeObj = this.$store.getters['getRoutes'].filter(value => value.routeId === this.route)[0];
      await this.$store.dispatch('doGetInspections', {route: routeObj});

    },

    async onTableSave(item) {
      // получаем маршрут
      const routeObj = this.$store.getters['getRoutes'].filter(value => value.routeId === this.route)[0];

      // формируем обследование
      let insp = {
        parkId: this.park,
        transportTypeId: this.$store.getters['getTS'].filter(value => value.ts_id === this.ts)[0].tt_id,
        routeId: this.route,
        dateIndexMin: dateToND(),
        dateMinString: dateToString(),
        dateIndexMax: dateToND(),
        dateMaxString: dateToString(),
        comm: item.comm,
        inspStatus: 1
      }
      // сохраняем обследование
      const inspID = await this.$store.dispatch('doAddInspections', {route: routeObj, inspection: insp});
      if (!inspID) {
        this.isSaveError = true;
        return;
      }
      insp.inspectionId = inspID;
      this.inspection = insp.inspectionId;
      localStorage.setItem('inspId', this.inspection);
      this.onTableCancel();
      await this.addReis();

    },

    onTableCancel() {
      this.isAddButtonDisabled = false;
      this.$router.replace(`/`);
    },

    async onStationsDialogShow() {
      const parkObj = this.$store.getters['getParks'].filter(value => value.parkId === this.park)[0];
      await this.$store.dispatch('doGetInspStations', {park: parkObj, inspReis: this.selectedRow});
      this.stations = this.$store.getters['getInspStations']
      await this.$router.replace(`/edit`);
    },

    onSubmit() {
      if (this.validateForm(this.$refs)) {
        this.isAddButtonDisabled = true;
        if (this.inspection == -2) {
          this.$router.replace(`/add`);
        } else {
          this.addReis();
        }
      }
    },

    // добавление нового рейса
    async addReis() {

      let inspReis = {
        insp_id: this.inspection,
        parkId: this.park,
        park_name: this.$store.getters['getParks'].filter(value => value.parkId === this.park)[0].parkTitleLong,
        transtype_id: this.$store.getters['getTS'].filter(value => value.ts_id === this.ts)[0].tt_id,
        grafic: this.graph,
        tsunique_id: this.$store.getters['getTS'].filter(value => value.ts_id === this.ts)[0].ts_id,
        ts_model_id: this.$store.getters['getTS'].filter(value => value.ts_id === this.ts)[0].md_id,
        // ts_model_name: this.$store.getters['getTS'].filter(value => value.ts_id === this.ts)[0].mk_name,
        mr_id: this.route,
        mr_name: this.$store.getters['getRoutes'].filter(value => value.routeId === this.route)[0].routeName,
        mv_id: this.marshVariant,
        rl_id: this.race,
        reislength: this.$store.getters['getRaces'].filter(value => value.raceId === this.race)[0].raceLengthAsKm,
        race: this.$store.getters['getRaces'].filter(value => value.raceId === this.race)[0].raceTitle,
        dir: this.$store.getters['getRaces'].filter(value => value.raceId === this.race)[0].raceType,
        garagNum: this.$store.getters['getTS'].filter(value => value.ts_id === this.ts)[0].ts_garagnum,
        date_asString: dateToString(),
        time_asString_full: getTimeWithSecondsFormat(new Date().getHours() * 3600 + new Date().getMinutes() * 60 + new Date().getSeconds()),
        time_asString_HHmm: getTimeFormat(new Date().getHours() * 3600 + new Date().getMinutes() * 60),
        dispatchdate_asInt: dateToND(),
        dispatchtime_asInt: new Date().getHours() * 3600 + new Date().getMinutes() * 60 + new Date().getSeconds(),
        weekday_index: new Date().getDay(),
        rowNum: this.rowList.length + 1,
      }
      let reis_id = await this.$store.dispatch('doAddReis', {
        park: this.$store.getters['getParks'].filter(value => value.parkId === this.park)[0],
        insp: this.$store.getters['getInspections'].filter(value => value.inspectionId === this.inspection)[0],
        inspReis: inspReis,
      });
      if (reis_id > 0) {
        inspReis.reis_id = reis_id;
        this.rowList.push(inspReis);
        this.isAddButtonDisabled = false;
      } else if (reis_id == false){
        this.isSaveError = true;
        this.isAddButtonDisabled = false;
        return;
      } else {
        await this.$router.replace(`/alert`);
      }
    },

    // получаем маршруты по парку
    async getRoutesAndTS() {
      const parkObj = this.$store.getters['getParks'].filter(value => value.parkId === this.park)[0];
      await this.$store.dispatch('doGetRoutes', {park: parkObj});
      // так как ТС получаются тоже по парку, получаем ТС
      await this.$store.dispatch('doGetTS', {park: parkObj});
      //сохраняем парк, для повторного вноса в комбобокс
      localStorage.setItem('parkId', this.park);

      //очищаем маршруты
      if (this.route != null) {
        localStorage.removeItem('routeId');
      }
      this.route = null;
      //очищаем период действия
      if (this.marshVariant != null) {
        localStorage.removeItem('mvId');
      }
      this.marshVariant = null;
      store.commit('clearMarshVariants');
      // очищаем тип рейса
      if (this.race != null) {
        localStorage.removeItem('raceId');
      }
      store.commit('clearRace');
      this.race = null;

      // очищаем запомненное ТС
      if (this.ts != null) {
        localStorage.removeItem('tsId');
      }
      this.ts = null

      // очищаем обследования
      if (this.inspection != null) {
        localStorage.removeItem('inspId');
      }
    },

    // получаем периоды действия
    async getMarshVariants() {
      // получаем периоды действия
      const routeObj = this.$store.getters['getRoutes'].filter(value => value.routeId === this.route)[0];
      await this.$store.dispatch('doGetMarshVariants', {route: routeObj});
      // получаем обследования
      await this.$store.dispatch('doGetInspections', {route: routeObj});
      // if (this.$store.getters['getMarshVariants'].length == 1 &&  localStorage.getItem('mvId')) {
      //   this.marshVariant = this.$store.getters['getMarshVariants'][0].routeVariantId;
      //   this.getRaces();
      // } else {
      // очищаем запомненный MarshVariant
      //сохраняем рейс, для повторного вноса в комбобокс
      localStorage.setItem('routeId', this.route);

      if (this.marshVariant != null) {
        localStorage.removeItem('mvId');
      }
      this.marshVariant = null;

      if (this.inspection != null) {
        localStorage.removeItem('inspId');
      }
      this.inspection = null;
      // }
      if (this.race != null) {
        localStorage.removeItem('raceId');
      }
      // очищаем тип рейса
      this.race = null;
      store.commit('clearRace');
    },

    // получаем рейсы
    async getRaces() {
      //сохраняем период действия, для повторного вноса в комбобокс
      localStorage.setItem('mvId', this.marshVariant);

      const routeObj = this.$store.getters['getMarshVariants'].filter(value => value.routeVariantId === this.marshVariant)[0];
      await this.$store.dispatch('doGetRaces', {marshVariant: routeObj});
      this.fillReises();
      this.race = null;
    },

    // заполнение рейсов в таблице рейсами из комбобокса
    fillReises() {
      if (this.$store.getters['getRaces'].length != 0) {
        this.rowList.map(value => {
          let raceTitle = (this.$store.getters['getRaces'].filter(val => val.raceId === value.rl_id)[0])
          if (raceTitle) {
            value.race = raceTitle.raceTitle
          }
        });
      }
    },

    // сетаем рейс
    setRace() {
      //сохраняем рейс, для повторного вноса в комбобокс
      localStorage.setItem('raceId', this.race);
    },

    // сетаем рейс
    setInsp() {
      //сохраняем рейс, для повторного вноса в комбобокс
      localStorage.setItem('inspId', this.inspection);
    },

    async getReises() {
      localStorage.setItem('tsId', this.ts);
      const tsObj = this.$store.getters['getTS'].filter(value => value.ts_id === this.ts)[0];
      await this.$store.dispatch('doGetInspReises', {ts: tsObj, dateIndex: dateToND()});
      let i = 1;
      this.rowList = this.$store.getters['getInspReises'].map(value => {
        value.mr_name = (this.$store.getters['getRoutes'].filter(val => val.routeId === value.mr_id)[0]).routeName
        value.rowNum = i
        i++
        return value;
      });
      this.fillReises();
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
    },

    // запускаем обновление данных
    async refreshData() {
      try {

        await this.$store.dispatch('doGetParks');

        // получаем парк
        if (this.park == null && localStorage.getItem('parkId')) {
          this.park = Number(localStorage.getItem('parkId'));
          await this.getRoutesAndTS();
          // сетаем ТС
          if (this.ts == null && localStorage.getItem('tsId')) {
            this.ts = Number(localStorage.getItem('tsId'));
            // сетаем рейсы
            await this.getReises();
          }
          // сетаем маршруты
          if (this.route == null && localStorage.getItem('routeId')) {
            this.route = Number(localStorage.getItem('routeId'))
            await this.getMarshVariants()
            if (this.marshVariant == null && localStorage.getItem('mvId')) {
              this.marshVariant = Number(localStorage.getItem('mvId'));
              await this.getRaces()
              if (this.race == null && localStorage.getItem('raceId')) {
                this.race = Number(localStorage.getItem('raceId'));
              }
            }
            if (this.inspection == null && localStorage.getItem('inspId')) {
              this.inspection = Number(localStorage.getItem('inspId'));
            }
          }
        }
      } finally {
        this.selectedRow = null;
      }
    },
  },

  // вызывается при создании компонента
  created() {
    // перезапрашиваются данные
    this.refreshData(true);
  },
};
</script>

<style scoped>
</style>
