<template>
  <input-select ref="select" v-model="value" caption="Выберите период действия..." :itemList="items"></input-select>
</template>

<script>
import {dateToString, ndToDate} from "@/lib";

export default {
  props: ['model-value'],
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)// routeVariantIndexBegin routeVariantIndexEnd
      }
    },
    items() {
      let marshVars = this.$store.getters['getMarshVariants'].map(item => {
        return {
          id: item.routeVariantId,
          title: dateToString(ndToDate(item.routeVariantIndexBegin)) + ' - ' +  dateToString(ndToDate(item.routeVariantIndexEnd)),
        }
      });

      // if(marshVars.length == 1) {
      //   this.$emit('update:model-value', marshVars[0].id)
      // }

      return marshVars;
    }
  },
  methods: {
    validate() {
      return this.$refs.select.validate()
    }
  },
}
</script>
