import {postRequest} from "@/store/Requests";
// import {getPasswordHash} from "@/lib";
import router from "@/router/Router";
// import {DoGetRaw} from "@/store/Actions";
import * as querystring from "querystring";

// ГЛАВНОЕ ХРАНИЛИЩЕ MAIN

export default {
    state() {
        return {
            // логин
            login: '',
            // пароль
            pwd: '',
            // токен
            tokenId: '',
            // левое меню открыто
            isLeftMenuShow: false,
            // версия сервера
            serverVersion: '',
            // время сервера
            serverTime: '',
            // версия клиента
            clientVersion: '',
            // признак успешной загрузки приложения
            isLoaded: false,
            // изначальный путь
            loadedUrl: null,
            // критическая ошибка
            isPanic: false,
            // текст критической ошибки
            panicMessage: '',
        }
    },
    getters: {
        // доступ к логину
        getLogin(state) {
            return state.login;
        },
        // доступ к паролю
        getPwd(state) {
            return state.pwd;
        },
        // доступ к токену
        getTokenId(state) {
            return state.tokenId
        },
        // признак авторизации
        getIsAuth(state) {
            return state.login !== '' && state.tokenId !== '';
        },
        // признак открытия левого меню
        getIsLeftMenuShow(state) {
            return state.isLeftMenuShow;
        },
        // версия сервера
        getServerVersion(state) {
            return state.serverVersion;
        },
        // время сервера
        getServerTime(state) {
            return state.serverTime;
        },
        // версия клиента
        getClientVersion(state) {
            return state.clientVersion;
        },
        // признак загрузки настроект
        getIsLoaded(state) {
            return state.isLoaded;
        },
        // изначальный url-адрес
        getLoadedUrl(state) {
            return state.loadedUrl;
        },
        // наличие критической ошибки
        getIsPanic(state) {
            return state.isPanic;
        },
        // текст критической ошибки
        getPanicMessage(state) {
            return state.panicMessage;
        }
    },
    mutations: {
        // установка параметров авторизации
        setAuth(state, payload) {
            state.login = payload.login;
            state.tokenId = payload.tokenId;
            localStorage.setItem('login', payload.login);
            localStorage.setItem('tokenId', payload.tokenId);
            localStorage.setItem('pwd', payload.pwd);
        },
        // сбрасываем параметры авторизации
        clearAuth(state) {
            state.login = '';
            state.tokenId = '';
            localStorage.removeItem('login');
            localStorage.removeItem('tokenId');
            localStorage.removeItem('pwd');
            localStorage.removeItem('parkId');
            localStorage.removeItem('tsId');
            localStorage.removeItem('routeId');
            localStorage.removeItem('mvId');
            localStorage.removeItem('raceId');
            localStorage.removeItem('inspId');
        },
        // сетаем версию сервера
        setServerStatus(state, payload) {
            state.serverVersion = payload.version;
            state.serverTime = payload.systime;
        },
        // устанавливаем изначальный url-адрес
        setLoadedUrl(state, payload) {
            state.loadedUrl = payload.loadedUrl;
        },
        // фиксируем успешную загрузку
        setLoaded(state) {
            state.isLoaded = true;
        },
        // устанавливаем критическую ошибку
        setPanic(state, payload) {
            state.isPanic = true;
            state.panicMessage = payload.panicMessage;
        }
    },
    actions: {
        // вход в систему
        doLogin: async function (context, payload) {
            // базовый адрес
            const baseUrl = context.getters['settings/getBaseUrl'];
            // console.log(baseUrl)
            // payload.pwd = '541268b';
            // payload.login = 'rychagov';
            try {
                // отправляем запрос
                const response = await postRequest(
                    baseUrl + '/AccountSessionRestController/authorizationRemote',
                    querystring.stringify({
                        login: payload.login,
                        app_name: 'asmppclient',
                        pwd: payload.pwd
                    }),
                );

                if (response.data == 'User does not exist!') {
                    return false;
                }

                if(response.data.toString().length > 50 ) {
                    return -1;
                }

                // проверяем ответ на корректность
                if (response.data && response.httpStatus == 'OK') {
                    const login = payload.login;
                    const tokenId = response.data;
                    const pwd = payload.pwd;
                    // сохраняем данные авторизации
                    context.commit('setAuth', {
                        login,
                        tokenId,
                        pwd
                    })


                    // переходим в корень или по изначальному пути
                    await router.replace(context.getters.getLoadedUrl ? context.getters.getLoadedUrl : '/');

                    // фиксируем успех
                    return true;
                } else {

                    // выходим с ошибкой
                    return false;
                }
            } catch (e) {

                return false;
            }
        },

        // выход из системы
        doLogout: async function (context) {
            // базовый адрес
            const baseUrl = context.getters['settings/getBaseUrl'];

            try {
                // отправляем запрос
                await postRequest(
                    baseUrl + '/AccountSessionRestController/unregisterRemote',
                    undefined,
                    context.getters.getTokenId
                );

                // сбрасываем данные авторизации
                await context.commit('clearAuth');

                // переходим в авторизацию
                await router.replace('/auth');
                // фиксируем успех
                return true;
            } catch (e) {
                // сбрасываем данные авторизации
                await context.commit('clearAuth');

                // переходим в авторизацию
                await router.replace('/auth');

                // выходим с ошибкой
                return false;
            }
        },

        // обновление сессии
        doUpdateSession: async function (context) {
            // базовый адрес
            const baseUrl = context.getters['settings/getBaseUrl'];

            try {
                // console.log('updateSession ', context.getters.getTokenId)
                // вызываем запрос
                let response = await postRequest(
                    baseUrl + '/AccountSessionRestController/reAuthorizationRemote',
                    undefined,
                    localStorage.getItem('tokenId')
                );

                if (response.data == false) {
                    // // сбрасываем данные авторизации
                    // await context.commit('clearAuth');
                    // переходим в авторизацию
                    // извлаекаем данные из локального хранилища
                    const login = localStorage.getItem('login');
                    const pwd = localStorage.getItem('pwd');
                    // есть данные в локальном хранилище
                    if (login && pwd) {

                        const response = await postRequest(
                            baseUrl + '/AccountSessionRestController/authorizationRemote',
                            querystring.stringify({
                                login: login,
                                app_name: 'asmppclient',
                                pwd: pwd
                            }),
                        );

                        if (response.data.toString().length < 50 ) {
                            const tokenId = response.data;
                            await context.commit('clearAuth');

                            context.commit('setAuth', {
                                login,
                                tokenId,
                                pwd
                            })
                            return true;
                        } else {
                            await context.commit('clearAuth');
                        }
                    } else {
                        return false;
                    }
                    return false;
                }

                return true;

            } catch (e) {
                // выходим с ошибкой
                return false;
            }
        },

        // автоматический вход в систему
        doAutoLogin: async function (context) {

            // запускаем таймер автообновления (раз в минуту)
            setInterval(() => {
                if (context.getters.getIsAuth) {
                    context.dispatch('doUpdateSession');
                }
            }, 25000)

            // извлаекаем данные из локального хранилища
            const login = localStorage.getItem('login');
            const tokenId = localStorage.getItem('tokenId');
            const pwd = localStorage.getItem('pwd');

            // есть данные в локальном хранилище
            if (login && tokenId && pwd) {

                // сохраняем их в нашем хранилище
                context.commit('setAuth', {
                    login,
                    tokenId,
                    pwd
                });

                // вызываем обновление сессии
                return await context.dispatch('doUpdateSession');
            }
            return false;
        },

        // устанавливаем панику
        doSetPanic: async (context, payload) => {
            // устанавливаем панику
            context.commit('setPanic', payload);
        },

        // устанавливаем признак загрузки
        doSetLoaded: (context) => {
            context.commit('setLoaded');
        },

        // устанавливаем начальный путь
        doSetLoadedUrl: (context, payload) => {
            context.commit('setLoadedUrl', payload);
        },
    }
}