<template>
  <div>
    <!-- Главное Меню -->
    <main-menu></main-menu>

    <!-- Основное окно -->
    <div id="main-div">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import MainMenu from './components/ui/menu/MainMenu.vue';

export default {
  components: {
    'main-menu': MainMenu,
  }
}
</script>

<style>
* {
  box-sizing: border-box;
}

html, body {
  height: 100vh;
}

#main-div {
  position: fixed;
  top: 60px;
  right: 5px;
  bottom: 5px;
  left: 5px;
}
</style>
