<template>
  <modal>
    <modal-dialog class="modal-dialog-centered modal-dialog-scrollable modal-lg">
      <modal-content>
        <modal-header class="bg-primary text-white">
          <modal-title>Ввод данных</modal-title>
          <button-close class="text-white" @click="$emit('onCancel')"></button-close>
        </modal-header>
        <modal-body>
          <form class="form">
            <div class="form-group" style="margin-bottom: 0">
              <input-button-option
                  :allowAllUp="false"
                  v-model="editMode"
                  :itemList="getButtons"
              ></input-button-option>
            </div>

            <!-- Таблица  -->
            <!--            <div class="form-group row" style="margin-bottom: 0" >-->
            <div class="table-container border rounded">
              <stations-edit-table
                  :rowList="getRowStationsList"
                  :selectedRow="selectedRow"
                  :columnList="columnList"
                  @onRowSelect="onRowSelect"
                  @onSaveStation="onSaveStation"
              >
              </stations-edit-table>
            </div>
            <!--            </div>-->
          </form>
        </modal-body>
        <!--        <modal-footer>-->
        <!--          <button @click="onCancel" class="btn btn-primary">Отмена</button>-->
        <!--          <button @click="onSubmit" class="btn btn-primary">Сохранить</button>-->
        <!--        </modal-footer>-->
      </modal-content>
    </modal-dialog>
  </modal>

  <!-- Оповещение о проблемах с сетью -->
  <confirm-message-box
      v-if="isSaveError"
      @onOK="onDialogCancel"
      title="Ошибка!"
  >Ошибка подключения, повторите попытку позже.
  </confirm-message-box>

</template>

<script>

// Диалог для редактирования данных обследования

import StationsEditTable from "@/components/stations/StationsEditTable";


export default {
  components: {StationsEditTable},
  props: ['item', 'op', 'stationsList', 'ts', 'parkId'],
  emits: ['onOK', 'onCancel', 'editSelectedRow'],
  data() {
    return {
      editMode: 1,
      selectedRow: null,
      isSaveError: false,
      rowList: [],
    };
  },

  methods: {

    async onSaveStation(item) {
      const parkObj = this.$store.getters['getParks'].filter(value => value.parkId === this.parkId)[0];

      item.doorsBlocked = true;

      const isSaved = await this.$store.dispatch('doUpdInspStations', {
        park: parkObj,
        inspStation: item,
        doorNum: this.editMode
      });

      if (!isSaved) {
        item.doorsBlocked = false;
        this.isSaveError = true;
      }
      this.stationsWithStyle();
    },

    onSubmit() {
      if (this.validateForm(this.$refs)) {
        if (this.op === 'add') {
          this.$emit('onOK', {
            selectedRow: this.selectedRow,
          })
        } else {
          this.$emit('editSelectedRow', {
            selectedRow: this.selectedRow,
            trip_type_id: this.selectedRow.trip_type_id
          })
        }
      }
    },

    // отменяем добавление
    onCancel() {
      this.$emit('onCancel')
    },

    // закрываем оповещение о проблемах с сетью
    onDialogCancel() {
      this.isSaveError = false;
    },

    // выбрана новая строка в таблице
    onRowSelect(item) {
      this.selectedRow = item;
      this.stationsWithStyle();
    },

    stationsWithStyle() {

      let stopsArr = this.stationsList;
      stopsArr.forEach(value => {

        if (value.doorsBlocked) {
          value['door1in__cellStyle'] = {
            backgroundColor: '#b4b4b4'
          }
          value["door1in__inputAttrs"] = {
            disabled: true
          };
          value['door1out__cellStyle'] = {
            backgroundColor: '#b4b4b4'
          };
          value["door1out__inputAttrs"] = {
            disabled: true
          };
          value['door2in__cellStyle'] = {
            backgroundColor: '#b4b4b4'
          }
          value["door2in__inputAttrs"] = {
            disabled: true
          };
          value['door2out__cellStyle'] = {
            backgroundColor: '#b4b4b4'
          };
          value["door2out__inputAttrs"] = {
            disabled: true
          };
          value['door3in__cellStyle'] = {
            backgroundColor: '#b4b4b4'
          }
          value["door3in__inputAttrs"] = {
            disabled: true
          };
          value['door3out__cellStyle'] = {
            backgroundColor: '#b4b4b4'
          };
          value["door3out__inputAttrs"] = {
            disabled: true
          };
          value['door4in__cellStyle'] = {
            backgroundColor: '#b4b4b4'
          }
          value["door4in__inputAttrs"] = {
            disabled: true
          };
          value['door4out__cellStyle'] = {
            backgroundColor: '#b4b4b4'
          };
          value["door4out__inputAttrs"] = {
            disabled: true
          };
          value['timeIndex__cellStyle'] = {
            backgroundColor: '#b4b4b4'
          };
          value["timeIndex__inputAttrs"] = {
            disabled: true,
            ticking: false
          };
          value['saveBtn__cellStyle'] = {
            backgroundColor: '#b4b4b4'
          };
          value["saveBtn__inputAttrs"] = {
            readonly: true,
            disabled: true
          };
        } else {

          value["timeIndex__inputAttrs"] = {
            readonly: true,
            ticking: true
          }
          value['door1in__cellStyle'] = {
            backgroundColor: '#ffffff'
          }
          value["door1in__inputAttrs"] = {
            disabled: false
          };
          value['door1out__cellStyle'] = {
            backgroundColor: '#ffffff'
          };
          value["door1out__inputAttrs"] = {
            disabled: false
          };
          value['door2in__cellStyle'] = {
            backgroundColor: '#ffffff'
          }
          value["door2in__inputAttrs"] = {
            disabled: false
          };
          value['door2out__cellStyle'] = {
            backgroundColor: '#ffffff'
          };
          value["door2out__inputAttrs"] = {
            disabled: false
          };
          value['door3in__cellStyle'] = {
            backgroundColor: '#ffffff'
          }
          value["door3in__inputAttrs"] = {
            disabled: false
          };
          value['door3out__cellStyle'] = {
            backgroundColor: '#ffffff'
          };
          value["door3out__inputAttrs"] = {
            disabled: false
          };
          value['door4in__cellStyle'] = {
            backgroundColor: '#ffffff'
          }
          value["door4in__inputAttrs"] = {
            disabled: false
          };
          value['door4out__cellStyle'] = {
            backgroundColor: '#ffffff'
          };
          value["door4out__inputAttrs"] = {
            disabled: false
          };
          value['timeIndex__cellStyle'] = {
            backgroundColor: '#ffffff'
          };
          value['saveBtn__cellStyle'] = {
            backgroundColor: '#ffffff'
          };
          value["saveBtn__inputAttrs"] = {
            readonly: true,
            disabled: true
          };
        }

      });
      this.rowList = stopsArr;
    },

  },

  computed: {

    getRowStationsList() {
      return this.rowList;
    },

    columnList() {
      this.stationsWithStyle()
      return [
        {
          field: "ordernum",
          caption: "№п/п",
          dataAlign: 'center',
          headerAlign: 'center',
          isSortable: false,
          show: false,
        },
        {
          field: "stname",
          caption: "Остановка",
          dataAlign: 'left',
          headerAlign: 'left',
          isSortable: false,
          displayFn(row) {
            return (row.stname).substring(0, 10);
          }
        },
        {
          field: "timeIndex",
          caption: "Время",
          sortType: 'sign',
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'tickingtime',
          isSortable: false,
          inputStyle: {
            marginTop: '4px'
          },
          inputAttrs: {
            disabled: false,
            class: "w-50",
            width: '30px',
          }
        },
        {
          field: 'door' + this.editMode + 'in',
          caption: "Вход",
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'integer',
          isSortable: false,
          inputStyle: {
            minWidth: '40px',
            marginTop: '4px'
          },
          inputAttrs: {
            class: "w-75",
            zeroing: true
          }
        },
        {
          field: 'door' + this.editMode + 'out',
          caption: "Выход",
          dataAlign: 'center',
          headerAlign: 'center',
          displayType: 'integer',
          isSortable: false,
          inputStyle: {
            minWidth: '40px',
            marginTop: '4px'
          },
          inputAttrs: {
            class: "w-75",
            zeroing: true
          }
        },
        {
          field: "saveBtn",
          caption: "",
          displayType: 'button',
          dataAlign: 'right',
          headerAlign: 'right',
          buttonCaption: '<i class="fas fa-save"></i>',
          buttonAttrs: {
            'class': "btn btn-outline-dark",
            'title': 'Сохранить',
            'type': 'button'
          },
          buttonClick: (row) => {
            this.onSaveStation(row);
          },
          show: true,
          isSortable: false,
        },
      ]
    },

    // отображение кнопок в зависисмости от параметров выбранного шага
    getButtons() {
      let buttons = [];
      buttons.push(
          {
            id: 1,
            title: '1 дверь'
          },
          {
            id: 2,
            title: '2 дверь'
          },
          {
            id: 3,
            title: '3 дверь'
          },
          {
            id: 4,
            title: '4 дверь'
          }
      )
      return buttons;
    }
  },
  // вызывается при создании компонента
  created() {
    if (this.ts == null) {
      this.onCancel()
    }
  },
}

</script>

<style scoped>
form label {
  font-weight: bold;
}

.table-container {
  position: absolute;
  top: 70px;
  left: 0px;
  width: 100%;
  bottom: 0px;
  overflow-y: auto;
}

.form {
  height: 600px;

}
</style>