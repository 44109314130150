import { DoPost} from "@/store/Actions";
import querystring from "querystring";

// ХРАНИЛИЩЕ периодов действия

export default {
    state() {
        return {
            // список объектов
            marshVariants: [],
            // время обновления кэша new Date().getTime()
            marshVariantsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            marshVariantsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getMarshVariants(state) {
            return state.marshVariants;
        },
    },
    mutations: {
        // установить новый список объектов
        setMarshVariants(state, payload) {
            state.marshVariants = payload;
            state.marshVariantsCache = new Date().getTime()
            // console.log('setMarshVariants', state.marshVariants)
        },

        //очищаем список объектов
        clearMarshVariants(state) {
            state.marshVariants = [];
        }
    },
    actions: {
        // получаем период действия по маршруту
        async doGetMarshVariants(context, payload) {
            let json = {};
            json.jsonMarsh = payload.route;
            let postMarsh = JSON.stringify(json.jsonMarsh);
            await DoPost(
                '/MarshVariantRestController/getMarshVariantRemote',
                querystring.stringify(json) + postMarsh,
                'setMarshVariants',
                'Получение периодов действия'
            )
        },


    }
}
