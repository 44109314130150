import { DoPost} from "@/store/Actions";
import querystring from "querystring";
import {clearObj} from "@/lib";

// ХРАНИЛИЩЕ ОСТАНОВОК РЕЙСА

export default {
    state() {
        return {
            // список объектов
            inspStations: [],
            // время обновления кэша new Date().getTime()
            inspStationsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            inspStationsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getInspStations(state) {
            return state.inspStations;
        },
    },
    mutations: {

        // установить новый список объектов
        setInspStations(state, payload) {
            state.inspStations = payload;
            state.inspStations.forEach(value => {
                if (value.timeIndex != 0 ) {
                    value['doorsBlocked'] = true;
                } else {
                    value['doorsBlocked'] = false;
                }
            })
            state.inspStationsCache = new Date().getTime()
        },

        // очищаем список объектов
        clearReis(state) {
            state.inspStations = [];
        },
    },
    actions: {

        // получение рейсов по дате и ТС
        async doGetInspStations(context, payload) {
            let json = {};
            json.jsonPark = JSON.stringify(payload.park);
            json.jsonInspReis = JSON.stringify(payload.inspReis);

            await DoPost(
                '/InspStationRestController/getReisArxStationsRemote',
                querystring.stringify(json),
                'setInspStations',
                'Получение рейсов'
            )
        },

        // сохранение нового Inspection
        async doUpdInspStations(context, payload) {

            let json = {};
            json.jsonPark = JSON.stringify(payload.park);
            payload.inspStation = clearObj(payload.inspStation);
            json.jsonInspStation = JSON.stringify(payload.inspStation)
            json.doorNum = JSON.stringify(payload.doorNum)
            return await DoPost(
                '/InspStationRestController/updateReisStationRemote',
                querystring.stringify(json),
                null,
                'Сохранение обследования'
            )
        },

    }
}
