<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" :disableSettings="true"
                    :rowsPerPage='5000'  name="tbl-stations"></edit-table-panel>
</template>

<script>

export default {
  props: ['isLoading', 'rowList', 'columnList'],
  emits:['onSaveStation'],
};
</script>
