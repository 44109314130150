<template>

  <!-- html -->
  <th
      class="text-nowrap"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-if="col.displayType === 'html' && col.displayFn && (col.isCaption || row.__isCaption)"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  ><span v-html="col.displayFn(row, rowIndex)"></span></th>

  <td
      class="text-nowrap"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-else-if="col.displayType === 'html' && col.displayFn"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  ><span v-html="col.displayFn(row, rowIndex)"></span></td>

  <th
      class="text-nowrap"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-else-if="col.displayType === 'html' && (col.isCaption || row.__isCaption)"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  ><span v-html="col.subField ? row[col.subField][col.field] : row[col.field]"></span></th>

  <td
      class="text-nowrap"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-else-if="col.displayType === 'html'"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  ><span v-html="col.subField ? row[col.subField][col.field] : row[col.field]"></span></td>

  <!-- text -->
  <th
      class="text-nowrap"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-else-if="col.displayType === 'text' && col.displayFn && (col.isCaption || row.__isCaption)"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  ><span>{{ col.displayFn(row, rowIndex) }}</span></th>

  <td
      class="text-nowrap"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-else-if="col.displayType === 'text' && col.displayFn"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  ><span>{{ col.displayFn(row, rowIndex) }}</span></td>

  <th
      class="text-nowrap"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-else-if="col.displayType === 'text' && (col.isCaption || row.__isCaption)"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  ><span>{{ col.subField ? row[col.subField][col.field] : row[col.field] }}</span></th>

  <td
      class="text-nowrap"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      v-else-if="col.displayType === 'text'"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  ><span>{{ col.subField ? row[col.subField][col.field] : row[col.field] }}</span></td>

  <!-- Переключатель -->
  <td
      v-else-if="col.displayType === 'checkbox'"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <input-checkbox-simple
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        v-model="value"
    ></input-checkbox-simple>
  </td>

  <!-- Ввод пробега -->
  <td
      v-else-if="col.displayType === 'length'"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <input-length
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        v-model="value">
    </input-length>
  </td>

  <!-- Ввод числа с плавающей точкой -->
  <td
      v-else-if="col.displayType === 'float'"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <input-float
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        v-model="value">
    </input-float>
  </td>

  <!-- Ввод целого числа -->
  <td
      v-else-if="col.displayType === 'integer'"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <input-integer
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        v-model="value">
    </input-integer>
  </td>

  <!-- Ввод денежной суммы -->
  <td
      v-else-if="col.displayType === 'currency'"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <input-currency
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        v-model="value">
    </input-currency>
  </td>

  <!-- Ввод дллительности в минутах -->
  <td
      v-else-if="col.displayType === 'minute'"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <input-minute
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        v-model="value">
    </input-minute>
  </td>

  <!-- Ввод времени в формате чч:мм -->
  <td
      v-else-if="col.displayType === 'time'"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <input-time
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        v-model="value">
    </input-time>
  </td>

  <!-- Ввод времени в формате чч:мм c возсожностью включить тикание времени -->
  <td
      v-else-if="col.displayType === 'tickingtime'"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <input-ticking-time
        v-bind="{...(col.inputAttrs || {}),...(row.__inputAttrs || {}),...(row[col.field+'__inputAttrs'] || {})}"
        :style="{...(col.inputStyle || {}),...(row.__inputStyle || {}),...(row[col.field+'__inputStyle'] || {})}"
        v-model="value">
    </input-ticking-time>
  </td>

  <!-- Кнопка -->
  <td
      v-else-if="col.displayType === 'button'"
      :style="{...(col.cellStyle || {}),...(row.__cellStyle || {}),...(row[col.field+'__cellStyle'] || {})}"
      :class="{'text-left': col.dataAlign === 'left', 'text-right': col.dataAlign === 'right', 'text-center': col.dataAlign === 'center'}"
  >
    <button
        v-html="col.buttonCaption"
        v-bind="{...(col.buttonAttrs || {}),...(row.__buttonAttrs || {}),...(row[col.field+'__buttonAttrs'] || {})}"
        :style="{...(col.buttonStyle || {}),...(row.__buttonStyle || {}),...(row[col.field+'__buttonStyle'] || {})}"
        @click="$event.stopPropagation();$emit('onCellButtonClick', col, row, rowIndex)"
    >
    </button>
  </td>

</template>

<script>
import InputInteger from "@/components/ui/custom/InputInteger";
export default {
  components: {InputInteger},
  props: [
      // колонка
      'col',
      // строка
      'row',
      // индекс колонки
      'colIndex',
      // индекс строки
      'rowIndex'
  ],
  emits: ['onCellChanged', 'onCellButtonClick'],
  computed: {
    value: {
      get() {
        return this.row[this.col.field];
      },
      set(value) {
        this.$emit('onCellChanged', this.col, this.row, value);
      }
    },
  },
}
</script>

<style scoped>

</style>