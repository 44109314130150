<template>
  <select
      v-model="value"
      v-bind="$attrs"
      @change="onChange"
      :class="{ 'is-invalid': isInvalid}"
      :disabled="readonly"
      @focus="onFocus"
      @blur="onBlur">
    <option
        v-for="item in items"
        :key="item.id"
        :value="item.id"
        :disabled="this.required && item.id === null"
    >{{ item.title }}</option>
  </select>
  <div class="invalid-feedback" v-if="!!errorText">{{ errorText }}</div>
  <div class="invalid-feedback" v-else>Выберите значение</div>
</template>

<script>
export default {
  emits: ['update:model-value'],
  props: {
    'model-value': {
      required: true
    },
    // массив элементов [{id: any, title: string}]
    itemList: {
      type: Array,
      required: true
    },
    // заголовок
    caption: {
      type: String,
      required: true
    },
    // требуется ввод значения
    'required': {
      type: Boolean,
      default: false
    },
    // только для чтения
    'readonly': {
      type: Boolean,
      default: false
    },
    'error': {
      type: String
    }
  },
  data() {
    return {
      isInvalid: false,
      errorText: this.error
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    },
    items() {
      const newItems = this.itemList.map(( item ) => {
        return {
          ...item
        }
      });
      // формируем нулевое значение
      newItems.unshift({
        id: null,
        title: this.caption
      })
      // возвращаем результат
      return newItems
    }
  },
  methods: {
    // проверка валидности
    isValid() {
      // проверяем разрешены ли пустые значения
      if (this.modelValue == null) {
        return !this.required
      }
      // все остальное можно
      return true;
    },
    // при получении фокуса
    onFocus() {
      // сбрасываем инвалидность
      this.isInvalid = false;
      this.errorText = this.error;
    },
    // при потере фокуса
    onBlur() {
      this.validate()
    },
    // при вводе значений
    onChange() {
      this.$nextTick(this.validate);
    },
    // вызывается для проверки формы
    validate() {
      const isValid = this.isValid()
      this.isInvalid = !isValid;
      return isValid
    },
    alarm(message) {
      this.errorText = message;
      this.isInvalid = true;
    }
  },
  created() {
    // на всякий случай - меняем undefined на null
    this.$watch(() => this.modelValue, ()=> {
      if (this.modelValue === undefined) {
        this.$emit('update:model-value', null);
      }
    }, {immediate: true});
    // следим за изменением текста
    this.$watch(()=> this.error, () => {
      this.errorText = this.error;
    })
  }
}
</script>
