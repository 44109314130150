<template>
  <input-time
    v-model="value"
  >
  </input-time>
</template>

<script>

import InputTime from "@/components/ui/custom/InputTime";

export default {
  components: {InputTime},
  data(){
    return {
      interval:null,
    }
  },
  props: {
    'model-value': {
      required: true
    },
    'ticking':{
      default: false,
      required: false
    }
  },
  emits: ['update:model-value'],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:model-value', value)

      }
    }
  },

  methods: {

    getNow: function() {
      const today = new Date();
      const time = today.getHours()*3600 +  today.getMinutes()*60 + today.getSeconds();
      if(this.ticking == true) {
       // this.value = time;
        this.$emit('update:model-value', time)
      }
    },

  },
  created() {
    this.interval = setInterval(this.getNow, 1000);
  },
  unmounted(){
    clearInterval(this.interval);
  }
}
</script>

<style scoped>

input::placeholder {
  color: #a9a9a9;
}

</style>