import axios from 'axios';
import store from './Store';
import router from '../router/Router';

// Выполнение post-запроса
export async function postRequest(url, json, tid) {

    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};

    if (tid) {
        headers.token = `${tid}`
    }

    try {
        // выполняем запрос
        const response = await axios.post(url, json, {
            headers
        });

        // определяем коды
        const httpCode = parseInt(response.status);
        const httpStatus = response.statusText;

        // логируем
        if (process.env.NODE_ENV === 'development') {
            console.debug('-----')
            console.debug(`${httpCode} ${httpStatus}`)
            console.debug(response.data)
        }

        // возвращаем результат
        return {
            httpCode,
            httpStatus,
            data: response.data
        }
    } catch (e) {
        // вернулся ответ с ошибкой
        if (e.response) {
            // запрашиваем статусы http
            const httpCode = parseInt(e.response.status);
            const httpStatus = e.response.statusText;

            // определяем внутренние ошибки
            let error = 0;
            let errorMessage = '';
            if (e.response.data && e.response.data['error'] && e.response.data['error_message']) {
                error = e.response.data['error'];
                errorMessage = e.response.data['error_message'];
            }

            // логируем
            if (process.env.NODE_ENV === 'development') {
                console.debug('-----')
                console.debug(`${httpCode} ${httpStatus}`)
                console.debug(e.response.data)
            }

            // если сервер вернул - требуется аутентификация
            if (httpCode == 401) {
                // запоминаем откуда уходим
                await store.dispatch('doSetLoadedUrl', {
                    loadedUrl: router.currentRoute.value
                });
                // очищаем данные аутентификации
                await store.commit('clearAuth');
                // переходим на страницу авторизации
                await router.replace('/auth');
            }

            // формируем ошибку
            const newError = new Error(errorMessage || httpStatus);
            newError.httpCode = httpCode;
            newError.httpStatus = httpStatus;
            newError.error = error;
            newError.errorMessage = errorMessage;
            throw newError;
        }
        // вернулся ответ без ошибки
        else {
            // формируем ошибку
            const newError = new Error('Ошибка сети');
            newError.httpCode = 0;
            newError.httpStatus = '';
            newError.error = 0;
            newError.errorMessage = '';
            throw newError;
        }
    }
}

// Выполнение сырой get-запрос
export async function getRawRequest(url, tid) {

    try {
        // выполняем запрос
        const response = await axios.get(url, {
            headers: {
                Authorization: tid ? `Bearer ${tid}` : null,
            },
        });
        // определяем коды
        const httpCode = parseInt(response.status);
        const httpStatus = response.statusText;

        // логируем
        if (process.env.NODE_ENV === 'development') {
            console.debug('-----')
            console.debug(`${httpCode} ${httpStatus}`)
            console.debug(response.data)
        }

        // возвращаем результат
        return {
            httpCode,
            httpStatus,
            data: response.data
        }
    } catch (e) {
        // вернулся ответ с ошибкой
        if (e.response) {
            // запрашиваем статусы http
            const httpCode = parseInt(e.response.status);
            const httpStatus = e.response.statusText;

            // логируем
            if (process.env.NODE_ENV === 'development') {
                console.debug('-----')
                console.debug(`${httpCode} ${httpStatus}`)
                console.debug(e.response.data)
            }
            // формируем ошибку
            const newError = new Error(httpStatus);
            newError.httpCode = httpCode;
            newError.httpStatus = httpStatus;
            throw newError;
        }
        // вернулся ответ без ошибки
        else {
            // формируем ошибку
            const newError = new Error('Ошибка сети');
            newError.httpCode = 0;
            newError.httpStatus = '';
            throw newError;
        }
    }
}