import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.css';

import { createApp } from 'vue'

import App from './App.vue'
import router from './router/Router';
import store from './store/Store';
import RegisterUI from "@/components/ui/RegisterUI";

// создаем приложение
const app = createApp(App);

// регистрируем маршрутизацию
app.use(router);

// регистрируем хранилище
app.use(store);

// регистрируем служебные компоненты
RegisterUI(app);


// запускаем главный компонент
app.mount('#app');