<template>
    <div class="dropdown-menu" :class="{show: isActive}">
        <slot></slot>
    </div>  
</template>

<script>
import ActiveMixin from "@/components/ui/bootstrap/mixins/ActiveMixin";
import ChildContentMixin from "@/components/ui/bootstrap/mixins/ChildContentMixin";

export default {
  mixins: [ActiveMixin, ChildContentMixin],
}
</script>
