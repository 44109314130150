import { createStore } from 'vuex';
import MainStore from "@/store/main/MainStore";
import SettingsStore from "@/store/settings/SettingsStore";
import ParksStore from "@/store/main/ParksStore";
import RoutesStore from "@/store/main/RoutesStore";
import MarshVariantsStore from "@/store/main/MarshVariantsStore";
import RacesStore from "@/store/main/RacesStore";
import TSStore from "@/store/main/TSStore";
import InspectionsStore from "@/store/main/InspectionsStore";
import RaceCardsStore from "@/store/main/RaceCardsStore";
import ReisesStore from "@/store/main/InspReisesStore";
import InspStationsStore from "@/store/main/InspStationsStore";

export default createStore({
    modules: {
        main: MainStore,
        settings: SettingsStore,
        parks: ParksStore,
        RoutesStore,
        MarshVariantsStore,
        RacesStore,
        TSStore,
        InspectionsStore,
        RaceCardsStore,
        ReisesStore,
        InspStationsStore,
    }
});