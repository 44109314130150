import { DoPost} from "@/store/Actions";
import querystring from "querystring";

// ХРАНИЛИЩЕ ОСТАНОВОК

export default {
    state() {
        return {
            // список объектов
            raceCards: [],
            // время обновления кэша new Date().getTime()
            raceCardsCache: null,
            // время жизни кэша в мс (null - бесконечно)
            raceCardsCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getRaceCards(state) {
            return state.raceCards;
        },
    },
    mutations: {
        // установить новый список объектов
        setRaceCards(state, payload) {
            state.raceCards = payload;
            state.raceCardsCache = new Date().getTime()
            // console.log('setRaceCards', state.raceCards)
        },
    },
    actions: {

        // получение остановок по паркуи рейсу
        async doGetRaceCards(context, payload) {
            let json = {};

            json.jsonPark = JSON.stringify(payload.park);
            json.jsonRace = JSON.stringify(payload.race);

            await DoPost(
                '/RaceCardRestController/getCardsRemote',
                querystring.stringify(json),
                'setRaceCards',
                'Получение остановог'
            )

        },
    }
}
