<template>
  <edit-table-panel :columnList="columnList" :rowList="rowList" :disableSettings="true"
                    :rowsPerPage='5000'  name="tbl-reises"></edit-table-panel>
</template>

<script>

import {getTimeFormat} from "@/lib";

export default {
  props: [ 'rowList'],
  data() {
    return {
      // колонки
      columnList: [
        // {
        //   field: "isSave",
        //   caption: "Иск.",
        //   isSortable: false,
        //   // displayFn(row) {
        //   //   if (row.__is_control_point) {
        //   //     return '<i class="fas fa-check">'
        //   //   }
        //   //   return ''
        //   // },
        // },
        {
          field: "rowNum",
          caption: "№п/п",
          dataAlign: 'center',
          headerAlign: 'center',
          isSortable: false,
        },
        {
          field: "mr_name",
          caption: "Марш.",
          dataAlign: 'center',
          headerAlign: 'center',
          isSortable: false,
        },
        {
          field: "race",
          caption: "Рейс",
          sortType: 'sign',
          dataAlign: 'center',
          headerAlign: 'center',
          isSortable: false,
        },
        {
          field: "dir",
          caption: "Напр.",
          sortType: 'sign',
          dataAlign: 'center',
          headerAlign: 'center',
          isSortable: false,
        },
        {
          field: "dispatchdate_asInt",
          caption: "Начало",
          dataAlign: 'center',
          headerAlign: 'center',
          isSortable: false,
          displayFn(row) {
            return getTimeFormat(row.dispatchtime_asInt)
          }
        },
        {
          field: "dispatchdate_asInt",
          caption: "Конец",
          dataAlign: 'center',
          headerAlign: 'center',
          isSortable: false,
          displayFn(row) {
            return getTimeFormat(row.dispatchtime_asInt)
          }
        },
        // {
        //   field: "stop_id",
        //   caption: "Длит.",
        //   sortType: 'string',
        //   dataAlign: 'center',
        //   headerAlign: 'center',
        //   isSortable: false,
        //   displayFn(row) {
        //     return getTimeFormat((row.dispatchdate_asInt - row.dispatchdate_asInt))
        //   }
        // },
        // {
        //   field: 'tm_finish_bgn',
        //   caption: 'Проб.',
        //   dataAlign: 'center',
        //   headerAlign: 'center',
        //   displayType: 'html',
        //   isSortable: false,
        //   displayFn(row) {
        //     return Math.abs(row.mileageAsKmEnd - row.mileageAsKmBegin)//.toFixed(1)
        //   }
        // },
        // {
        //   field: "transportation",
        //   caption: "Перев.",
        //   dataAlign: 'right',
        //   headerAlign: 'right',
        //   isSortable: false
        // },
        // {
        //   field: "disbal",
        //   caption: "Дисб.%",
        //   sortType: 'string',
        //   dataAlign: 'center',
        //   headerAlign: 'center',
        //   isSortable: false
        // },
        // {
        //   field: 'irmabugnumber',
        //   caption: 'Ош.',
        //   dataAlign: 'center',
        //   headerAlign: 'center',
        //   displayType: 'html',
        //   isSortable: false,
        // },
        // {
        //   field: 'passedThroughCount',
        //   caption: 'Мимо',
        //   dataAlign: 'center',
        //   headerAlign: 'center',
        //   displayType: 'html',
        //   isSortable: false,
        // }
      ],
    };
  },
  computed: {

  },
};
</script>
