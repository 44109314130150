// ГЛАВНОЕ ХРАНИЛИЩЕ SETTINGS

import {DoGetRaw} from "@/store/Actions";

export default {
    namespaced: true,
    state() {
        return {
            baseUrl: '',
        }
    },
    mutations: {
        setSettings(state, payload) {
            if (payload.baseUrl)
                state.baseUrl = payload.baseUrl;
        }
    },
    getters:{
        getBaseUrl(state) {
            return state.baseUrl;
        },
    },
    actions: {
        // запрос настроек из json-файла
        async doGetSettings({state}) {
            const result = await DoGetRaw(
                '/settings.json',
                'settings/setSettings',
                'Загрузка настроек',
                false
            );
            // должно быть подключение
            return result && state.baseUrl;
        }
    }
}
