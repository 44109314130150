<template>
  <div>
    <!-- Форма авторизации -->
    <auth-form
      @onEnter="onEnter"
      :disableButton="showError || showUserExist"
    ></auth-form>
    <!-- Спинер -->
    <loading v-if="isLoading"></loading>
  </div>

  <!-- Оповещение об ошибке авторизации -->
  <confirm-message-box
      v-if="showError"
      @onOK="onTableCancel"
      title="Ошибка!"
  >Ошибка авторизации.
  </confirm-message-box>

  <!-- Оповещение о том, что такой пользователь уже залогинен -->
  <confirm-message-box
      v-if="showUserExist"
      @onOK="onCancel"
      title="Ошибка!"
  >Данный пользователь уже вошел в систему.
  </confirm-message-box>

</template>

<script>
import AuthForm from '../../components/main/AuthForm';


export default {


  components: {
    AuthForm
  },

  data() {
    return {
      isLoading: false,
      showError:false,
      showUserExist:false,
    };
  },

  methods: {
    async onEnter(data) {
      this.isLoading = true;
      try {

        let res = await this.$store.dispatch("doLogin", data)
        if (res == false){
          this.showError = true;
        } else if (res == -1) {
          this.showUserExist = true;
        }
      }
      finally {
        this.isLoading = false;
      }
    },

    onTableCancel(){
      this.showError = false;
    },

    onCancel(){
      this.showUserExist = false;
    }
  },
};
</script>


<style>
confirm-message-box{
  border: ;

}

</style>