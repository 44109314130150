import { DoPost} from "@/store/Actions";
import querystring from "querystring";
import store from "@/store/Store";

// ХРАНИЛИЩЕ РЕЙСОВ

export default {
    state() {
        return {
            // список объектов
            races: [],
            // время обновления кэша new Date().getTime()
            racesCache: null,
            // время жизни кэша в мс (null - бесконечно)
            racesCacheTimeout: null
        }
    },
    getters: {
        // вернуть список объектов
        getRaces(state) {
            return state.races;
        },
    },
    mutations: {

        // установить новый список объектов
        setRaces(state, payload) {
            state.races = payload;
            state.racesCache = new Date().getTime()
            // console.log('setRaces', state.races)
        },

        // очищаем список объектов
        clearRace(state) {
            state.races = [];
        },
    },
    actions: {

        // получение рейсов по времени действия
        async doGetRaces(context, payload) {
            store.commit('clearRace')
            let json = {};
            json.jsonMarshVariant = payload.marshVariant;
            let postMV = JSON.stringify(json.jsonMarshVariant);
            await DoPost(
                '/RaceRestController/getReisesRemote',
                querystring.stringify(json) + postMV,
                'setRaces',
                'Получение рейсов'
            )

        },
    }
}
